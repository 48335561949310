import axios from "@/api";

const state = {
  isLoading: false,
  allNotifications: [],
  alerts: [],
  announcements: [],
  reminders: [],
  page: {
    all: 1,
    alert: 1,
    announcement: 1,
    reminder: 1,
  },
  perPage: 10,
  total: {
    all: 0,
    alert: 0,
    announcement: 0,
    reminder: 0,
  },
};

const getters = {
  getAllNotifications: (state) => state.allNotifications,
  getAlerts: (state) => state.alerts,
  getAnnouncements: (state) => state.announcements,
  getReminders: (state) => state.reminders,
  getNotificationCounts: (state) => ({
    allNotifications: state.total.all,
    alerts: state.total.alert,
    announcements: state.total.announcement,
    reminders: state.total.reminder,
  }),
};

const mutations = {
  setLoading(state, isLoading) {
    state.isLoading = isLoading;
  },
  setNotifications(state, { type, notifications, total, page }) {
    state.total[type === null ? "all" : type] = total;
    state.page[type === null ? "all" : type] = page;

    if (page === 1) {
      state[type === null ? "allNotifications" : `${type}s`] = notifications;
    } else {
      state[type === null ? "allNotifications" : `${type}s`].push(
        ...notifications
      );
    }
  },
  addNotification(state, notification) {
    state.allNotifications.unshift(notification);
    switch (notification.notification_type) {
      case "Alert":
        state.alerts.unshift(notification);
        break;
      case "Announcement":
        state.announcements.unshift(notification);
        break;
      case "Reminder":
        state.reminders.unshift(notification);
        break;
    }
  },

  incrementPage(state, type) {
    state.page[type]++;
 
  },
};

const actions = {
  async fetchNotifications({ commit, state }, { type_filter, page = 1 }) {
    commit("setLoading", true);
    try {
      const response = await axios.get(`/data/patient/notifications`, {
        params: { type_filter, page, per_page: state.perPage },
      });

      commit("setNotifications", {
        type: type_filter,
        notifications: response.data.notifications,
        total: response.data.total,
        page,
      });
    } catch (error) {
      console.error(`Error fetching notifications for type: ${type_filter}`, error);
    } finally {
      commit("setLoading", false);
    }
  },
  async fetchAllNotifications({ dispatch }) {
    await Promise.all([
      dispatch("fetchNotifications", { type_filter: null }),
      dispatch("fetchNotifications", { type_filter: "alert" }),
      dispatch("fetchNotifications", { type_filter: "announcement" }),
      dispatch("fetchNotifications", { type_filter: "reminder" }),
    ]);
  },

  //checks if there are more items to load before incrementing the page and fetching more notifications
  async loadMoreNotifications({ commit, state, dispatch }, { type_filter }) {
    const currentPage = state.page[type_filter];
    const totalItems = state.total[type_filter];
    const loadedItems = state[type_filter === null ? "allNotifications" : `${type_filter}s`].length;
    

    if (loadedItems < totalItems) {

      commit("incrementPage", type_filter);
      await dispatch("fetchNotifications", {
        type_filter,
        page: state.page[type_filter],
      });
    } else {
 
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
