<template>
  <div
    class="mx-auto px-2 lg:px-4 py-4 min-h-[calc(100vh-80px)] flex flex-col space-y-8 lg:space-y-0 lg:flex-row lg:space-x-10"
  >
    <div
      ref="scrollContainer"
      class="xl:mx-10 rounded-lg shadow-xl w-full bg-white p-4 md:p-10 overflow-auto no-scrollbar"
      style="height: 80vh"
      @scroll="handleScroll"
    >
      <div class="w-full px-2 md:px-5 py-6 rounded-xl">
        <NotificationTabs
          :filter="filter"
          @setNotificationFilter="setTypeFilter"
        />

        <div
          v-if="isLoading && currentPage[filter] === 1"
          class="w-full h-64 flex justify-center items-center"
        >
          <Spinner />
        </div>

        <div
          v-else-if="filteredNotifications.length === 0"
          class="w-full h-64 flex justify-center items-center"
        >
          <div
            class="flex flex-col justify-center items-center py-10 space-y-4"
          >
            <div
              class="w-[120px] h-[120px] bg-WhiteLilac p-6 rounded-full flex justify-center items-center"
            >
              <img :src="bellRingingIcon" alt="bell ringing icon" />
            </div>
            <div class="text-Cinder text-center">
              <p class="text-2xl font-bold mb-4">You're All Caught Up!</p>
              <p>You have no new notifications at the moment.</p>
              <p class="text-base">
                Check back later for updates on your appointments, health
                reminders, and personalized health tips.
              </p>
            </div>
          </div>
        </div>

        <div v-else>
          <div v-for="(period, index) in periodsWithData" :key="index">
            <h2 class="text-lg font-semibold mb-4 text-center text-SkyGrey">
              {{ period.title }}
            </h2>
            <div class="overflow-x-auto overflow-y-visible no-scrollbar">
              <NotificationCard
                v-for="notification in filteredNotificationsByPeriod(period)"
                :key="notification.id"
                :notification="notification"
              />
            </div>
          </div>
          <div
            v-if="isLoading && currentPage[filter] > 1"
            class="w-full flex justify-center items-center py-4"
          >
            <Spinner />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, watchEffect, onMounted, onUnmounted } from "vue";
import { useRoute, useRouter } from "vue-router";
import { mapActions, mapState } from "@/hooks/mapStore";
import Spinner from "@/components/icons/SpinnerIcon.vue";
import NotificationTabs from "@/components/main/patient/notifications/NotificationTabs.vue";
import NotificationCard from "@/components/main/patient/notifications/NotificationCard.vue";
import bellRingingIcon from "@/assets/icons/bell-ringing.svg";

const { notifications } = mapState();
const {
  "notifications/loadMoreNotifications": loadMoreNotifications,
  "notifications/fetchAllNotifications": fetchAllNotifications,
} = mapActions();

const route = useRoute();
const router = useRouter();

const appointmentTypes = ["all", "alert", "announcement", "reminder"];

let routeQuery = route.query.type;

if (!appointmentTypes.includes(routeQuery)) {
  routeQuery = "all";
}

const filter = ref(routeQuery);
const isLoading = computed(() => notifications.value.isLoading);
const currentPage = computed(() => notifications.value.page);
const timePeriods = ref([
  { key: "today", title: "Today" },
  { key: "yesterday", title: "Yesterday" },
  { key: "last_week", title: "Last Week" },
  { key: "last_month", title: "Last Month" },
]);

const initializeNotifications = async () => {
  await fetchAllNotifications();
};

const filteredNotifications = computed(() => {
  switch (filter.value) {
    case "alert":
      return notifications.value.alerts;
    case "announcement":
      return notifications.value.announcements;
    case "reminder":
      return notifications.value.reminders;
    default:
      return notifications.value.allNotifications;
  }
});

const filteredNotificationsByPeriod = (period) => {
  const now = new Date();
  let periodStart = new Date(now);
  let periodEnd = new Date(now);

  switch (period.key) {
    case "today":
      periodStart.setHours(0, 0, 0, 0);
      break;
    case "yesterday":
      periodStart.setDate(periodStart.getDate() - 1);
      periodStart.setHours(0, 0, 0, 0);
      periodEnd = new Date(periodStart);
      periodEnd.setHours(23, 59, 59, 999);
      break;
    case "last_week":
      periodStart.setDate(periodStart.getDate() - 7);
      periodStart.setHours(0, 0, 0, 0);
      periodEnd.setDate(periodEnd.getDate() - 2); // Exclude yesterday and today
      periodEnd.setHours(23, 59, 59, 999);
      break;
    case "last_month":
      periodStart.setMonth(periodStart.getMonth() - 1);
      periodStart.setHours(0, 0, 0, 0);
      periodEnd.setDate(periodEnd.getDate() - 8); // Exclude last week, yesterday, and today
      periodEnd.setHours(23, 59, 59, 999);
      break;
  }

  return filteredNotifications.value.filter((notification) => {
    const notificationDate = new Date(notification.created_at);
    return notificationDate >= periodStart && notificationDate <= periodEnd;
  });
};

const periodsWithData = computed(() => {
  return timePeriods.value.filter(
    (period) => filteredNotificationsByPeriod(period).length > 0
  );
});

const setTypeFilter = (type) => {
  filter.value = type;
  router.push(`/patient/notifications?type=${type}`);
};

const scrollContainer = ref(null);

const handleScroll = async () => {
  if (!scrollContainer.value) return;

  const { scrollTop, scrollHeight, clientHeight } = scrollContainer.value;

  if (scrollTop + clientHeight >= scrollHeight - 100 && !isLoading.value) {
    await loadMoreNotifications({ type_filter: filter.value });
  }
};

onMounted(() => {
  if (scrollContainer.value) {
    scrollContainer.value.addEventListener("scroll", handleScroll);
  }
});

onUnmounted(() => {
  if (scrollContainer.value) {
    scrollContainer.value.removeEventListener("scroll", handleScroll);
  }
});

watchEffect(() => {
  initializeNotifications();
});
</script>
