<template>
  <div class="mx-auto px-2 lg:px-4 py-4 min-h-[calc(100vh-80px)] flex flex-col space-y-8 lg:space-y-0 lg:flex-row lg:space-x-10">
    <div class="xl:mx-10 rounded-lg shadow-xl w-full bg-white p-4 md:p-10 overflow-auto no-scrollbar">
      <div class="w-full border px-2 md:px-5 py-6 rounded-xl">
        <div class="flex justify-between items-center gap-3 mb-3">
          <p class="font-urbanist-regular font-bold">My Appointments</p>

          <button
            v-if="filteredAppointments.length > 0"
            class="bg-ResolutionBlue py-1.5 px-10 md:py-2 md:px-12 rounded-[40px] text-white text-xs md:text-sm font-semibold hover:bg-DodgerBlue hover:text-white transition-all duration-300"
            @click="openAppointmentModal"
          >
            Book Now
          </button>
        </div>

        <!-- Always show the tabs -->
        <Tab :status="currentStatus" @changeStatus="changeStatus" />

        <!-- Loading state -->
        <div v-if="isLoading" class="w-full h-64 flex justify-center items-center">
          <img src="../../../assets/icons/loading-01.svg" alt="loading icon" class="animate-spin" />
        </div>

        <!-- Error state -->
        <div v-else-if="isError" class="text-center text-red-500">
          An error occurred while fetching appointments.
        </div>

        <!-- Empty state -->
        <div v-else-if="filteredAppointments.length === 0" class="text-center py-4 text-DavyGrey">
          No {{ currentStatus }} appointments available.
        </div>

        <!-- Appointments Table -->
        <div v-else>
          <div class="overflow-x-auto custom-scrollbar md:mt-8">
            <table class="w-full">
              <thead>
                <tr>
                  <th class="min-w-[130px] py-3 text-left text-sm font-bold text-[#25282B]">Health Center</th>
                  <th class="min-w-[80px] py-3 text-left text-sm font-bold text-[#25282B]">City</th>
                  <th class="min-w-[220px] py-3 pr-2 text-left text-sm font-bold text-[#25282B]">Address</th>
                  <th class="min-w-[130px] py-3 text-left text-sm font-bold text-[#25282B] relative flex items-center gap-1.5">
                    <span>HC Phone</span>
                    <span
                      class="bg-ResolutionBlue w-3 h-3 rounded-full text-white text-[0.625rem] flex justify-center items-center cursor-pointer relative group"
                    >
                      i
                      <span
                        class="hidden group-hover:block absolute -bottom-1 right-1/2 translate-x-1/2 translate-y-full bg-GhostWhite w-36 py-2 px-3 rounded-lg text-[#25282B] text-center"
                      >
                        Health center phone
                      </span>
                    </span>
                  </th>
                  <th class="min-w-[100px] py-3 text-left text-sm font-bold text-[#25282B]">Type</th>
                  <th class="min-w-[100px] py-3 text-left text-sm font-bold text-[#25282B]">Status</th>
                  <th class="min-w-[100px] py-3 text-left text-sm font-bold text-[#25282B]">Date</th>
                  <th class="min-w-[100px] py-3 text-left text-sm font-bold text-[#25282B]">Time</th>
                  <th class="min-w-[100px] py-3">
                    <span class="sr-only">Actions</span>
                  </th>
                </tr>
              </thead>

              <tbody>
                <tr v-for="appointment in filteredAppointments" :key="appointment.id" class="border-t border-gray-200">
                  <td class="min-w-[100px] py-3 text-DavyGrey text-sm">{{ appointment?.health_centre?.name }}</td>
                  <td class="min-w-[80px] py-3 text-DavyGrey text-sm">{{ appointment?.city_of_centre }}</td>
                  <td class="min-w-[220px] py-3 pr-2 text-DavyGrey text-sm cursor-pointer" :title="appointment?.health_centre?.address">
                    {{ appointment?.health_centre?.address }}
                  </td>
                  <td class="min-w-[130px] py-3 text-DavyGrey text-sm cursor-pointer">{{ appointment?.health_centre?.phone }}</td>
                  <td class="min-w-[100px] py-3 text-DavyGrey text-sm capitalize">{{ appointment?.type }}</td>
                  <td class="min-w-[100px] py-3 text-sm first-letter:capitalize" :class="statusStyles(appointment?.status)">
                    {{ appointment?.status }}
                  </td>
                  <td class="min-w-[200px] py-3 text-DavyGrey text-sm">{{ appointment?.date && dateFormatter_2(appointment?.date) }}</td>
                  <td class="min-w-[100px] py-3 text-DavyGrey text-sm">{{ appointment?.time && appointment?.time.slice(0, 5) }}</td>
                  <td v-if="appointment?.status !== 'Cancelled' && appointment?.status !== 'Expired' && appointment?.status !== 'Completed'" class="min-w-[100px] py-3 text-DavyGrey">
                    <v-menu open-on-hover>
                      <template v-slot:activator="{ props }">
                        <button v-bind="props" class="hover:bg-WhiteLilac p-2 text-2xl md:text-3xl font-bold rounded flex justify-center items-center transition-colors duration-300">
                          <img :src="optionButton" alt="three dots" />
                        </button>
                      </template>
                      <AppointmentActionBox @edit="setAppointmentModal(appointment)" @cancel="cancelCurrentAppointment(appointment)" />
                    </v-menu>
                  </td>
                </tr>
                <tr v-if="filteredAppointments.length === 0">
                  <td colspan="9" class="text-center py-4 text-DavyGrey">No {{ currentStatus }} appointments available.</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <CancelAppointmentModal
        v-if="showCancelModal"
        :appointment="selectedAppointment"
        @close="closeCancelModal"
        @cancel-appointment="handleCancelAppointment"
      />
    </div>
  </div>
</template>

<script setup>
import { ref, computed, onMounted, inject } from "vue";
import { useStore } from 'vuex';
import { useRoute, useRouter } from 'vue-router';
import { mapActions, mapGetters } from "vuex";
import { toast } from "vue3-toastify";
import { dateFormatter_2 } from "@/utils/dateFormatter";
import Tab from "@/components/main/patient/appointment/Tab.vue";
import AppointmentActionBox from "@/components/main/patient/appointment/AppointmentActionBox.vue";
import optionButton from "@/assets/icons/option-button.svg";
import CancelAppointmentModal from "@/components/main/patient/appointment/CancelAppointmentModal.vue"

const store = useStore();
const route = useRoute();


const { fetchPatientAppointments, cancelAppointment } = mapActions('appointment', [
  'fetchPatientAppointments',
  'cancelAppointment'
]);

const { getAppointmentsByStatus } = mapGetters('appointment', [
  'getAppointmentsByStatus'
]);

const openAppointmentModal = inject("openAppointmentModal");
const setAppointmentModalVal = inject("setAppointmentModalVal");
const isLoading = ref(false);
const isError = ref(false);
const currentStatus = ref(route.query.status || "all");

const showCancelModal = ref(false);
const selectedAppointment = ref(null);


const filteredAppointments = computed(() => {
  return store.getters['appointment/getAppointmentsByStatus'](currentStatus.value);
});

const changeStatus = async (status) => {
  currentStatus.value = status;
  await fetchAppointmentsByStatus(status);
};

const fetchAppointmentsByStatus = async (status) => {
  isLoading.value = true;
  isError.value = false;
  try {
    await store.dispatch('appointment/fetchPatientAppointments', status);
  } catch (error) {
    isError.value = true;
    toast.error("Error fetching appointments", { autoClose: 2000 });
  } finally {
    isLoading.value = false;
  }
};

onMounted(() => {
  fetchAppointmentsByStatus(currentStatus.value);
});

const statusStyles = (status) => {
  if (status === "Scheduled" || status === "Rescheduled") {
    return "text-BrightBlue";
  } else if (status === "Pending") {
    return "text-OrangePeel";
  } else if (status === "Completed") {
    return "text-DarkMint";
  } else if (status === "Cancelled" || status === "Expired") {
    return "text-DeepCarminePink";
  } else {
    return "text-DavyGrey";
  }
};

const handleCancelAppointment = async (appointmentId) => {
  console.log("Appointment canceled:", appointmentId);
  // Close the cancel modal
  closeCancelModal();
  // Refresh the appointment list
  await fetchAppointmentsByStatus(currentStatus.value);
};

const setAppointmentModal = (value) => {
  setAppointmentModalVal({
    type: "edit",
    country: value.health_centre?.country,
    state: value.state_of_centre,
    city: value.city_of_centre,
    center: value.name_of_centre,
    appointmentReason: value.reason_for_appointment,
    address: value.health_centre.address,
    date: value.date ? value.date : changeDateFormat(value.health_centre.created_at),
    time: value.health_centre.time ? value.health_centre.time : value.health_centre.created_at,
    appointmentId: value.id,
  });

  openAppointmentModal();
};

const cancelCurrentAppointment = (appointment) => {
  selectedAppointment.value = appointment;
  showCancelModal.value = true;
};

const closeCancelModal = () => {
  showCancelModal.value = false;
  selectedAppointment.value = null;
};

</script>

