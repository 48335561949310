<template>
  <ModalWrapper>
    <div
      class="max-w-[724px] w-full space-y-[30px] bg-white p-6 rounded-3xl md:p-[50px] lg:rounded-[38px] relative"
    >
      <div class="flex justify-between items-center">
        <h3 class="text-ResolutionBlue text-xl font-bold">
          {{ selectedTestType }}
        </h3>
        <div class="cursor-pointer" @click="closeTestInstructionModal">
          <span
            class="w-4 h-0.5 bg-transparent relative inline-block before:w-full before:h-full before:bg-black before:absolute before:left-0 before:top-0 before:rotate-45 after:w-full after:h-full after:bg-black after:absolute after:left-0 after:top-0 after:-rotate-45"
          ></span>
        </div>
      </div>

      <div class="flex flex-col items-center gap-[30px]">
        <div class="w-[180px] h-[160px] border border-BluishGrey rounded-sm">
          <img
            :src="selectedDeviceImage"
            alt="Selected Device"
            class="w-full h-full object-contain"
          />
        </div>

        <div class="space-y-6">
          <p class="text-center text-base font-bold text-BalticSea">
            Please follow these steps:
          </p>

          <ul class="text-center text-sm font-normal text-Gravel space-y-1">
            <li>1. Insert a test strip into the device</li>
            <li>
              2. Place a sample of the blood on the sensitive tip of the test
              strip
            </li>
            <li>3. Wait till the countdown is done</li>
          </ul>
        </div>
      </div>

      <div class="flex items-center gap-2.5 justify-center mt-8">
        <button
          @click="closeTestInstructionModal"
          class="px-[52px] py-3 border border-ResolutionBlue text-ResolutionBlue rounded-full text-sm font-semibold"
        >
          Cancel
        </button>
        <button
          class="px-[52px] py-3 bg-ResolutionBlue text-white rounded-full text-sm font-semibold"
        >
          Hold on
        </button>
      </div>
    </div>
  </ModalWrapper>
</template>

<script setup>
import { inject, defineProps, ref, onMounted, watch } from "vue";
import { useStore } from "vuex";
import ModalWrapper from "./ModalWrapper.vue";

const store = useStore();

const props = defineProps({
  selectedDeviceImage: String,
  selectedTestType: String,
});

const closeTestInstructionModal = inject("closeTestInstructionModal");
const openManualTestModal = inject("openManualTestModal");
const closeTakeTestModal = inject("closeTakeTestModal");

//  watch for changes in glucose data from Vuex store
watch(
  () => store.getters["socket/glucoseData"],
  (newData) => {
    if (newData) {
      // Close the instructions modal and open the manual test modal
      closeTestInstructionModal();
      openManualTestModal(
        props.selectedTestType,
        store.getters["user/getPatientId"], 
        newData
      );
    }
  },
  { immediate: true, deep: true }
);
</script>
