<template>
  <div
    class="mx-auto px-2 lg:px-4 py-4 min-h-[calc(100vh-80px)] flex flex-col space-y-8 lg:flex-row lg:space-x-10"
  >
    <div
      class="xl:mx-10 rounded-lg shadow-xl w-full h-full bg-white p-4 md:p-10"
    >
      <div class="w-full h-full flex flex-col">
        <!-- <div class="border rounded-3xl p-5 h-[23rem]">
            <div class="h-[10%]">
              <div class="flex items-center justify-between">
                <p class="font-semibold">Blood Glucose</p>
                <div class="flex items-center space-x-4">
                  <div class="flex items-center space-x-2">
                    <div class="bg-BilobaFlower w-2 h-2 rounded-full"></div>
                    <span class="text-Dolphin text-[10px] lg:text-xs"
                      >Before meal</span
                    >
                  </div>
                  <div class="flex items-center space-x-2">
                    <div class="bg-TealishGreen w-2 h-2 rounded-full"></div>
                    <span class="text-Dolphin text-[10px] lg:text-xs"
                      >After meal</span
                    >
                  </div>
                </div>
                <button
                  class="border-[0.1px] border-OlsoGrey rounded-md px-4 py-1.5 text-xs"
                >
                  Today
                </button>
              </div>
            </div>
            <div class="h-[90%]">
              <div class="h-full flex justify-center items-center">
                <div class="flex flex-col space-y-6 items-center">
                  <AddChartIcon />
                  <p class="text-sm text-DavyGrey">You have no chart yetww</p>
                  <button
                    class="w-fit rounded-3xl bg-ResolutionBlue text-white px-10 py-3 text-sm font-semibold"
                  >
                    Capture your vitals
                  </button>
                </div>
              </div>
            </div>
          </div> -->
        <div class="w-full border rounded-3xl p-5 h-full">
          <div class="h-[10%] flex items-center justify-between">
            <p class="font-semibold">History</p>
            <div class="flex flex-row items-center space-x-6">

              <div class="relative">
                <button
                  @click="toggleFilterDropdown"
                  class="border rounded-lg px-4 py-2 flex items-center cursor-pointer"
                >
                  {{ selectedFilter }}
                  <img
                    src="@/assets/icons/arrow_drop_down.svg"
                    alt="arrow icon"
                    class="ml-2"
                  />
                </button>
                <div v-if="showFilterDropdown" class="absolute bg-white shadow-lg rounded-lg mt-2">
                  <ul>
                    <li @click="setFilter('Last week')" class="px-4 py-2 cursor-pointer hover:bg-gray-200">Last week</li>
                    <li @click="setFilter('Last month')" class="px-4 py-2 cursor-pointer hover:bg-gray-200">Last month</li>
                    <li @click="setFilter('Last year')" class="px-4 py-2 cursor-pointer hover:bg-gray-200">Last year</li>
                  </ul>
                </div>
              </div>
              <div
                class="w-fit rounded-3xl px-4 py-2 bg-OrangePeel text-white text-xs"
              >
                Avg:
                <span class="font-semibold">{{ average_high }}mg/dl</span>
              </div>
              <div class="flex space-x-4">
                <div class="flex items-center space-x-2">
                  <div class="bg-DodgerBlue w-1 h-1 rounded-full"></div>
                  <span class="text-DodgerBlue text-[10px] lg:text-xs"
                    >Low</span
                  >
                </div>
                <div class="flex items-center space-x-2">
                  <div class="bg-DarkMint w-1 h-1 rounded-full"></div>
                  <span class="text-DarkMint text-[10px] lg:text-xs"
                    >Normal</span
                  >
                </div>
                <div class="flex items-center space-x-2">
                  <div class="bg-OrangePeel w-1 h-1 rounded-full"></div>
                  <span class="text-OrangePeel text-[10px] lg:text-xs"
                    >Average High</span
                  >
                </div>
                <div class="flex items-center space-x-2">
                  <div class="bg-ArtyClickRed w-1 h-1 rounded-full"></div>
                  <span class="text-ArtyClickRed text-[10px] lg:text-xs"
                    >High</span
                  >
                </div>
                <div class="flex items-center space-x-2">
                  <button class="bg-ResolutionBlue/[0.05] p-2 rounded-md" @click="exportToPDF">
                <img
                  src="../../../assets/icons/fi_share.svg"
                  alt="share icon"
                />
              </button>
                </div>
              </div>
            </div>
          </div>
          <div class="h-[90%]">
            <div class="overflow-x-auto h-full py-6">
              <table
              v-if="filteredRecords.length > 0"
                class="w-full"
              >
                <thead>
                  <tr>
                    <th
                    @click="sortBy('glucose_level')"
                      class="min-w-[100px] py-3 border-b border-gray-200 text-left text-sm font-bold text-[#25282B] cursor-pointer"
                    >
                      <div class="flex gap-2 items-center">
                        <span>
                        Glucose Level
                      </span>

                      <span>
                        <img :class="getSortIconClass('glucose_level')" src="@/assets/icons/sort_asc_desc.svg" alt="sort icon" />
                      </span>
                      </div>
                    </th>

                    <th
                    @click="sortBy('before_meal')"
                      class="min-w-[130px] pl-5 py-3 border-b border-gray-200 text-left text-sm font-bold text-[#25282B] cursor-pointer"
                    >
                    <div class="flex gap-2 items-center">
                      <span>
                        Meal
                      </span>
                      <span>
                        <img :class="getSortIconClass('before_meal')" src="@/assets/icons/sort_asc_desc.svg" alt="sort icon" />
                      </span>
                    </div>
                    </th>
                    <th
                    @click="sortBy('check_time')"
                      class="min-w-[130px] pl-5 py-3 border-b border-gray-200 text-left text-sm font-bold text-[#25282B] cursor-pointer"
                    >
                    <div class="flex gap-2 items-center">
                      <span>
                      Date
                    </span>
                      <span>
                        <img :class="getSortIconClass('check_time')" src="@/assets/icons/sort_asc_desc.svg" alt="sort icon" />
                      </span>
                    </div>
                    </th>
                    <th
                    @click="sortBy('check_time')"
                      class="min-w-[130px] pl-5 py-3 border-b border-gray-200 text-left text-sm font-bold text-[#25282B] cursor-pointer"
                    >
                    <div class="flex gap-2 items-center">
                      <span>
                        Time
                      </span>
                      <span>
                        <img :class="getSortIconClass('check_time')" src="@/assets/icons/sort_asc_desc.svg" alt="sort icon" />
                      </span>
                    </div>
                    </th>
                    <th
                      class="min-w-[130px] pl-5 py-3 border-b border-gray-200 text-left text-sm font-bold text-[#25282B]"
                    >
                    <div class="flex gap-2 items-center">
                      <span>
                        Input
                      </span>
                      <span>
                        <img src="@/assets/icons/sort_asc_desc.svg" alt="sort icon" />
                      </span>
                    </div>
                    </th>
                    <th
                      class="min-w-[130px] pl-5 py-3 border-b border-gray-200 text-left text-sm font-bold text-[#25282B]"
                    ></th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                  v-for="(glucose_record, index) in sortedRecords"
                    :key="index"
                    class="font-light"
                  >
                    <td
                      class="min-w-[100px] py-5 border-t border-gray-200 text-sm"
                      :class="[
                        glucose_record?.glucose_level < hypoglycaemic
                          ? 'text-DodgerBlue'
                          : glucose_record?.glucose_level >= hypoglycaemic &&
                            glucose_record?.glucose_level <= normal
                          ? 'text-DarkMint'
                          : glucose_record?.glucose_level > normal &&
                            glucose_record?.glucose_level <= average_high
                          ? 'text-OrangePeel'
                          : glucose_record?.glucose_level >= high
                          ? 'text-ArtyClickRed'
                          : '',
                      ]"
                    >
                      {{ glucose_record?.glucose_level }} mg/dl
                    </td>
                    <td
                      class="min-w-[130px] pl-5 py-5 border-t border-gray-200 text-sm"
                    >
                      {{
                        glucose_record?.before_meal === true
                          ? "Before meal"
                          : "After meal"
                      }}
                    </td>
                    <td
                      class="min-w-[130px] pl-5 py-5 border-t border-gray-200 text-sm"
                    >
                      {{ dateFormatter(glucose_record?.check_time) }}
                    </td>
                    <td
                      class="min-w-[130px] pl-5 py-5 border-t border-gray-200 text-sm"
                    >
                      {{ timeFormatter(glucose_record?.check_time) }}
                    </td>
                    <td
                      class="min-w-[130px] pl-5 py-5 border-t border-gray-200 text-sm"
                    >
                      {{ glucose_record?.input }}
                    </td>
                    <td
                      v-if="glucose_record?.input === 'Manual Input'"
                      class="min-w-[130px] pl-5 py-5 border-t border-gray-200 text-sm"
                    >
                      <button
                        class="border border-ResolutionBlue rounded-2xl text-ResolutionBlue px-4 py-1 font-bold hover:bg-ResolutionBlue hover:text-white"
                      >
                        Edit
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>

              <p v-else class="w-full text-center p-4">No vitals</p>
              <!-- <div
                  v-if="totalPages > 1"
                  class="flex justify-center items-center space-x-3 border-t pt-4"
                >
                  <button
                    @click="goToPage(page - 1)"
                    :disabled="page === 1"
                    class="text-white bg-ResolutionBlue px-3 py-1.5 rounded-md"
                  >
                    Previous
                  </button>
                  <span>Page {{ page }} of {{ totalPages }}</span>
                  <button
                    @click="goToPage(page + 1)"
                    :disabled="page === totalPages"
                    class="text-white bg-ResolutionBlue px-3 py-1.5 rounded-md"
                  >
                    Next
                  </button>
                </div> -->
            </div>
            <!-- <div v-else class="h-full flex justify-center items-center">
                <div class="flex flex-col space-y-6 items-center">
                  <img
                    src="../../../assets/icons/blood_glucose_history_icon.svg"
                    alt=""
                    class="w-12"
                  />
                  <p class="text-sm text-DavyGrey">You have no history yet</p>
                  <button
                    class="w-fit rounded-3xl bg-ResolutionBlue text-white px-10 py-3 text-sm font-semibold"
                  >
                    Capture your vitals
                  </button>
                </div>
              </div> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed } from "vue";
import { mapGetters } from "@/hooks/mapStore";
import { dateFormatter, timeFormatter } from "@/utils/dateFormatter";
import jsPDF from "jspdf";
import "jspdf-autotable";
import dayjs from 'dayjs';

const hypoglycaemic = ref(70);
const normal = ref(100);
const average_high = ref(125);
const high = ref(126);

const selectedFilter = ref('Last week');
const showFilterDropdown = ref(false);

const sortField = ref(null);
const sortDirection = ref(null);

const toggleFilterDropdown = () => {
  showFilterDropdown.value = !showFilterDropdown.value;
};

const setFilter = (filter) => {
  selectedFilter.value = filter;
  showFilterDropdown.value = false;
};

const { "user/getUserBloodGlucoseRecords": getUserBloodGlucoseRecords } = mapGetters();

const postData = computed(() => getUserBloodGlucoseRecords.value);

const paginatedRecords = computed(() => {
  if (postData.value && postData.value.results) {
    const formattedRecords = postData.value.results.map((record) => {
      const formattedDate = dateFormatter(record.check_time);
      const formattedTime = timeFormatter(record.check_time);
      return { ...record, formattedDate, formattedTime };
    });

    return formattedRecords;
  }
  return [];
});


const filteredRecords = computed(() => {
  const records = getUserBloodGlucoseRecords?.value?.results || [];
  const today = dayjs();

  if (!Array.isArray(records)) return [];

  if (selectedFilter.value === 'Last week') {
    return records.filter(record => dayjs(record.check_time).isAfter(today.subtract(1, 'week')));
  } else if (selectedFilter.value === 'Last month') {
    return records.filter(record => dayjs(record.check_time).isAfter(today.subtract(1, 'month')));
  } else if (selectedFilter.value === 'Last year') {
    return records.filter(record => dayjs(record.check_time).isAfter(today.subtract(1, 'year')));
  } else {
    return records;
  }
});

const sortBy = (field) => {
  if (sortField.value === field) {
    if (sortDirection.value === 'asc') {
      sortDirection.value = 'desc';
    } else if (sortDirection.value === 'desc') {
      sortDirection.value = null;
      sortField.value = null;
    } else {
      sortDirection.value = 'asc';
    }
  } else {
    sortField.value = field;
    sortDirection.value = 'asc';
  }
};

const getSortIconClass = (field) => {
  if (sortField.value !== field) return '';

  return {
    'rotate-0': sortDirection.value === 'asc',
    'rotate-180': sortDirection.value === 'desc',
    'opacity-50': !sortDirection.value,
  };
};

const sortedRecords = computed(() => {
  if (!sortField.value || !sortDirection.value) return filteredRecords.value;

  const records = filteredRecords.value;
  return records.sort((a, b) => {
    let fieldA = a[sortField.value];
    let fieldB = b[sortField.value];

    if (typeof fieldA === 'string') {
      fieldA = fieldA.toLowerCase();
      fieldB = fieldB.toLowerCase();
    }

    if (sortDirection.value === 'asc') {
      return fieldA > fieldB ? 1 : -1;
    } else if (sortDirection.value === 'desc') {
      return fieldA < fieldB ? 1 : -1;
    }
  });
});


const exportToPDF = () => {
  const records = paginatedRecords.value;

  if (!records || records.length === 0) {
    console.log("No blood glucose data available for export.");
    return;
  }

  const doc = new jsPDF();
  const columns = ["Glucose Level", "Meal", "Date", "Time", "Input"];
  const rows = records.map((record) => [
    `${record.glucose_level} mg/dl`,
    record.before_meal ? "Before meal" : "After meal",
    record.formattedDate,
    record.formattedTime,
    record.input,
  ]);

  doc.text("Blood Glucose History", 14, 15);
  doc.autoTable({
    head: [columns],
    body: rows,
    startY: 20,
    styles: {
      fontSize: 10,
      halign: "left",
    },
  });

  doc.save("blood_glucose_history.pdf");
};

// Export to CSV
const exportToCSV = () => {
  const records = paginatedRecords.value;

  if (!records || records.length === 0) {
    console.log("No blood glucose data available for export.");
    return;
  }

  const csvRows = [];
  const headers = ["Glucose Level", "Meal", "Date", "Time", "Input"];
  csvRows.push(headers.join(","));

  records.forEach((record) => {
    const row = [
      `${record.glucose_level} mg/dl`,
      record.before_meal ? "Before meal" : "After meal",
      record.formattedDate,
      record.formattedTime,
      record.input,
    ];
    csvRows.push(row.join(","));
  });

  const blob = new Blob([csvRows.join("\n")], { type: "text/csv" });
  const url = window.URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", "blood_glucose_history.csv");
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

</script>

<style scoped>
.rotate-180 {
  transform: rotate(180deg);
}

.opacity-50 {
  opacity: 0.5;
}
</style>