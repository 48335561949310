<template>
  <main
    class="mx-auto px-2 lg:px-4 py-4 min-h-[calc(100vh-80px)] flex flex-col space-y-8 lg:space-y-0 lg:flex-row lg:space-x-10"
  >
    <section
      class="xl:mx-10 w-full flex flex-col gap-6 bg-white p-5 lg:gap-11 lg:py-9 lg:px-12 lg:rounded-md lg:shadow-2xl"
    >
      <h2 class="font-bold text-xl lg:text-2xl">Wellness Profile</h2>

      <section
        class="max-w-4xl w-full flex flex-col gap-6 justify-center lg:flex-row lg:items-start lg:justify-start lg:gap-12"
      >
        <div class="w-[120px] h-[120px] rounded-full self-center lg:self-start">
          <img
            :src="centerInfo?.avatar_url || noAvatar"
            alt="profile image"
            class="w-full h-full rounded-full"
          />
        </div>

        <div
          class="space-y-2.5"
          :class="{
            'border-b border-b-BlueChalk mb-4': openGeneralInfo !== true,
            'mb-12': openGeneralInfo === true,
            'transition-transform duration-700': true,
          }"
        >
          <div
            class="flex items-center justify-between space-x-4 cursor-pointer"
            @click="toggleGeneralInfo"
          >
            <h4 class="font-bold text-sm lg:text-base">General Info</h4>
            <div>
              <span>
                <img
                  :src="Arrow"
                  :class="{
                    'rotate-180': openGeneralInfo !== true,
                    'transition-transform': true,
                    'duration-700': true,
                  }"
                  alt="arrow icon"
                />
              </span>
            </div>
          </div>

          <p class="text-MistBlue font-semibold text-xs md:text-sm">
            Wellness Centre
          </p>

          <div
            v-if="fetchStatus === 'loading'"
            class="h-20 lg:w-56 flex justify-center items-center"
          >
            <img :src="loader" alt="loading icon" class="animate-spin" />
          </div>

          <div
            v-else-if="fetchStatus === 'success'"
            :class="[
              {
                'max-h-0': true,
                'overflow-hidden': true,
                'transition-max-height': true,
                'duration-700': true,
                'max-h-[500px]': openGeneralInfo === true,
              },
            ]"
          >
            <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
              <InputField
                label="Name"
                :placeholder="centerInfo?.name"
                :disabled="true"
              />
              <InputField label="Email" :placeholder="centerInfo?.email" />
              <InputField label="Address" :placeholder="centerInfo?.address" />
              <InputField
                label="Phone Number"
                type="number"
                :placeholder="centerInfo?.phone"
              />
            </div>
          </div>

          <p v-else>Somthing went wrong</p>
        </div>
      </section>
    </section>
  </main>
</template>

<script setup>
import { ref, watch } from "vue";
import { useRoute } from "vue-router";
import { mapActions } from "@/hooks/mapStore";
import InputField from "@/components/main/ui/InputField.vue";
import Arrow from "@/assets/icons/arrow-up-iocn.svg";
import loader from "@/assets/icons/loading-01.svg";

const noAvatar =
  "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png";

const route = useRoute();
const id = route.params.id;

const openGeneralInfo = ref(true);
const fetchStatus = ref("loading");
const centerInfo = ref(null);

const { "accountConnection/getLinkedCenterInfo": getLinkedCenterInfo } =
  mapActions();

const getInfo = async () => {
  try {
    fetchStatus.value = "loading";
    const res = await getLinkedCenterInfo({ id });
    fetchStatus.value = "success";
    centerInfo.value = res.health_center_profile;
  } catch (error) {
    fetchStatus.value = "error";
    console.error({ error });
  }
};

getInfo();

const toggleGeneralInfo = () => {
  if (fetchStatus.value === "success") {
    openGeneralInfo.value = !openGeneralInfo.value;
  }
};


</script>
