<template>
  <div class="w-full h-full flex flex-col">
    <SearchPatient
      :iconSrc="searchIcon"
      :containerClasses="'space-y-6 md:w-full lg:w-[70%] xl:w-[40%] mb-6'"
      :searchBarClasses="'flex flex-row items-center justify-between border rounded-md px-2 py-2.5'"
      :inputClasses="'bg-transparent w-full focus:outline-0 px-3 py-1 text-sm'"
      placeholder="Search for patient by username"
      @selectPatient="openAccountLinkingModal"
    />

    <div class="flex-grow flex flex-col">
      <div
        v-if="
          !isFetchingPatients && getPatientRequests?.connected?.length === 0
        "
        class="h-20 flex justify-center items-center"
      >
        <p>You have no linked accounts</p>
      </div>

      <div
        v-else-if="!isFetchingPatients && getPatientRequests"
        class="flex-grow flex flex-col"
      >
        <div
          v-if="getPatientRequests?.connected?.length > 0"
          class="border rounded-3xl px-4 py-8 flex-grow flex flex-col"
        >
          <div class="flex items-center justify-between mb-6">
            <div class="flex items-center gap-x-1.5">
              <p class="font-semibold text-lg md:text-xl">Linked Accounts</p>
              <span
                v-if="getPatientRequests?.connected"
                class="bg-ResolutionBlue text-white w-5 h-5 text-xs rounded-full flex justify-center items-center"
                >{{ getPatientRequests?.connected?.length }}</span
              >
            </div>
          </div>

          <div class="flex-grow overflow-auto custom-scrollbar">
            <table class="w-full">
              <thead>
                <tr>
                  <th
                    class="sticky top-0 bg-white min-w-[100px] py-3 border-b border-gray-200 text-left text-xs font-bold text-[#25282B]"
                  >
                    Name
                  </th>
                  <th
                    class="sticky top-0 bg-white min-w-[100px] pl-5 py-3 border-b border-gray-200 text-left text-xs font-bold text-[#25282B]"
                  >
                    Relationship
                  </th>
                  <th
                    class="sticky top-0 bg-white min-w-[200px] pl-5 py-3 border-b border-gray-200 text-left text-xs font-bold text-[#25282B]"
                  >
                    Date
                  </th>
                  <th
                    class="sticky top-0 bg-white min-w-[100px] pl-5 py-3 border-b border-gray-200 text-left text-xs font-bold text-[#25282B]"
                  >
                    Time
                  </th>
                  <th
                    class="sticky top-0 bg-white min-w-[100px] pl-5 py-3 border-b border-gray-200 text-left text-xs font-bold text-[#25282B]"
                  ></th>
                </tr>
              </thead>
              <tbody>
                <tr
                  class="mb-4 font-light"
                  v-for="account in getPatientRequests?.connected"
                  :key="account.id"
                >
                  <LinkedAccounts :account="account" />
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div v-else class="h-20 flex justify-center items-center">
        <img :src="loader" alt="loader icon" class="animate-spin" />
      </div>
    </div>
  </div>

  <AccountLinkingModal
    v-if="showAccountLinkingModal"
    :result="selectedResult"
    @close="closeAccountLinkingModal"
  />
</template>

<script setup>
import { ref, provide, onMounted } from "vue";
import { mapActions, mapGetters } from "@/hooks/mapStore";
import LinkedAccounts from "./LinkedAccounts.vue";
import AccountLinkingModal from "./AccountLinkingModal.vue";
import loader from "@/assets/icons/loading-01.svg";
import SearchPatient from "@/components/main/ui/SearchPatient.vue"
import searchIcon from "@/assets/icons/Search.svg"

const {
  "user/searchPatient": searchPatient,
  "accountConnection/fetchPatientRequests": fetchPatientRequests,
} = mapActions();

const { "accountConnection/getPatientRequests": getPatientRequests } =
  mapGetters();


const showAccountLinkingModal = ref(false);
const selectedResult = ref(null);
const isFetchingPatients = ref(false);
const isLoading = ref(false);


const noAvatar =
  "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png";

// open account linking modal
const openAccountLinkingModal = (result) => {
  selectedResult.value = result;
  showAccountLinkingModal.value = true;
};

// close account linking modal
const closelinkAccountModal = () => {
  showAccountLinkingModal.value = false;
};

provide("closelinkAccountModal", closelinkAccountModal);


const getAllRequests = async () => {
  isLoading.value = true;
  try {
    await fetchPatientRequests();
  } catch (error) {
    console.error("err", error);
  } finally {
    isLoading.value = false;
  }
};

getAllRequests();

onMounted(async () => {
  isFetchingPatients.value = true;
  await fetchPatientRequests();
  isFetchingPatients.value = false;
});

const updateFullName = (event) => {
  relationship.value = event.target.value;
};

const closeAccountLinkingModal = () => {
  searchKeyword.value = "";
  showAccountLinkingModal.value = false;
};
</script>
