<template>
  <div class="absolute top-[2%] z-50 lg:ml-20">
    <div
      class="text-xs md:text-base flex items-center justify-between rounded-md bg-LavendaMist p-4 mb-4 space-x-5"
    >
      <span>
        <img src="../../assets/icons/thank-you-icon.svg" alt="" />
      </span>
      <span>Thank you for signing up with Turbomedics</span>
      <span class="cursor-pointer">x</span>
    </div>
  </div>
</template>
