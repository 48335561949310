<template>
  <div
    class="fixed top-0 left-0 z-[75] h-screen w-full bg-Dawn/20 backdrop-blur-sm flex justify-center overflow-y-scroll no-scrollbar"
  >
    <form
      @submit.prevent="sendInvite"
      class="w-full max-w-[500px] bg-white rounded-[38px] p-6 md:p-10 lg:p-12 m-auto relative"
    >
      <div>
        <div class="flex items-center justify-between mb-6">
          <div class="flex items-center space-x-2 md:space-x-6">
            <p
              @click="closelinkAccountModal"
              class="font-semibold cursor-pointer text-OlsoGrey"
            >
              Account Linking
            </p>
            <DirectionalRightIcon width="8" height="12" />
            <p class="text-ResolutionBlue font-semibold cursor-pointer">
              Send Invite
            </p>
          </div>
          <div class="self-start cursor-pointer" @click="closelinkAccountModal">
            <span
              class="w-5 h-0.5 bg-transparent inline-block relative before:absolute before:w-full before:h-full before:bg-GunMetal before:left-0 before:-top-0 before:rounded-sm before:-rotate-45 after:absolute after:w-full after:h-full after:bg-GunMetal after:left-0 after:top-0 after:rounded-sm after:rotate-45"
            ></span>
          </div>
        </div>
        <div class="space-y-6 md:space-y-10">
          <div>
            <label for="name" class="text-sm text-BluishGrey">Name</label>
            <input
              class="text-sm md:text-[14px] w-full border-b border-BlueChalk text-DarkJungle focus:outline-none focus:border-ResolutionBlue focus:ring-0 font-bold pb-5"
              type="text"
              name=""
              :value="result?.full_name || result?.name"
              readonly
            />
          </div>

          <div class="relative">
            <label for="relationship" class="text-BluishGrey text-sm"
              >Relationship*</label
            >
            <template v-if="relationship === 'Other'">
              <input
                id="relationship"
                class="text-sm md:text-[14px] w-full border-b border-BlueChalk text-DarkJungle focus:outline-none focus:border-ResolutionBlue focus:ring-0 font-bold pb-5"
                v-model="relationshipOther"
                placeholder="Please specify"
                required
              />
            </template>
            <template v-else>
              <div class="relative">
                <div
                  @click="toggleDropdown"
                  class="cursor-pointer w-full border-b border-BlueChalk text-DarkJungle focus:outline-none focus:border-ResolutionBlue focus:ring-0 font-bold pb-5 flex justify-between items-center"
                >
                  <span class="text-sm md:text-[14px]">{{
                    relationship || "Select relationship"
                  }}</span>
                  <img
                    src="../../../../assets/icons/down_caret.svg"
                    alt="down caret icon"
                    class="cursor-pointer"
                  />
                </div>
                <div
                  v-if="isDropdownOpen"
                  class="absolute z-10 w-full bg-white border border-BlueChalk mt-1"
                >
                  <template v-if="result?.type === 'HealthCentre'">
                    <div
                      @click="selectRelationship('Test Centre')"
                      class="p-2 hover:bg-gray-100 cursor-pointer"
                    >
                      Test Centre
                    </div>
                  </template>
                  <template v-else>
                    <div
                      v-for="(option, index) in relationshipOptions"
                      :key="index"
                      @click="selectRelationship(option.value)"
                      class="p-2 hover:bg-gray-100 cursor-pointer"
                    >
                      {{ option.label }}
                    </div>
                  </template>
                </div>
              </div>
            </template>
          </div>
          <button
            type="submit"
            class="bg-ResolutionBlue text-white text-sm font-semibold rounded-[44px] py-[13px] px-[52px] w-44"
          >
            <span v-if="isLoading" class="pl-4">
              <LoadingSpinner />
            </span>
            <span v-else>Send invite</span>
          </button>
        </div>
      </div>
    </form>
  </div>
</template>

<script setup>
import { ref, inject, watch } from "vue";
import DirectionalRightIcon from "@/components/icons/DirectionalRightIcon.vue";
import LoadingSpinner from "@/components/icons/LoadingSpinner.vue";
import { mapActions } from "@/hooks/mapStore";
import { toast } from "vue3-toastify";

const props = defineProps(["result"]);
const emit = defineEmits(["close"]);
const isLoading = ref(false);
const isDropdownOpen = ref(false);

const originalCloseLinkAccountModal = inject("closelinkAccountModal");


const toggleDropdown = () => {
  isDropdownOpen.value = !isDropdownOpen.value;
};

const selectRelationship = (value) => {
  relationship.value = value;
  isDropdownOpen.value = false;
};

const {
  "accountConnection/sendPatientInvite": sendPatientInvite,
  "accountConnection/fetchPatientRequests": fetchPatientRequests,
} = mapActions();

const relationship = ref("");
const relationshipOther = ref("");

const relationshipOptions = [
  { label: "Brother", value: "Brother" },
  { label: "Sister", value: "Sister" },
  { label: "Mother", value: "Mother" },
  { label: "Father", value: "Father" },
  { label: "Neighbour", value: "Neighbour" },
  { label: "Friend", value: "Friend" },
  { label: "Other", value: "Other" },
];

// New wrapper function to close modal and reset states
const closelinkAccountModal = () => {
  isDropdownOpen.value = false; // Reset dropdown state
  relationship.value = ''; // Reset relationship value
  relationshipOther.value = ''; // Reset other relationship value
  originalCloseLinkAccountModal(); // Call the original close function
};

const sendInvite = async () => {
  try {
    isLoading.value = true;
    const relationshipValue =
      relationship.value === "Other"
        ? relationshipOther.value
        : relationship.value;
    await sendPatientInvite({
      relationship: relationshipValue,
      id: props.result.id,
    });
    toast.success("Request sent successfully", {
      autoClose: 3000,
    });
    relationship.value = "";
    relationshipOther.value = "";
    fetchPatientRequests();
    // emit("close");
    closelinkAccountModal(); 
  } catch (error) {
    if (error?.response?.data?.message === "Relationship is missing") {
      toast.error(error?.response?.data?.message, {
        autoClose: 3000,
      });
    } else if (
      error?.response?.data?.error ===
      "You cannot send a connection request to yourself"
    ) {
      toast.error(error?.response?.data?.error, {
        autoClose: 3000,
      });
    } else if (
      error?.response?.data.error === "Connection already established"
    ) {
      toast.error(error?.response?.data?.error, {
        autoClose: 3000,
      });
    } else {
      toast.error(error?.response?.data?.error, {
        autoClose: 3000,
      });
    }
  } finally {
    isLoading.value = false;
  }
};

watch(relationship, (newVal) => {
  if (newVal !== "Other") {
    relationshipOther.value = "";
  }
});
</script>
