<template>
  <div class="relative">
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.00004 11.901C11.7595 11.901 13.4987 11.4187 13.6667 9.48294C13.6667 7.54847 12.4541 7.67287 12.4541 5.29934C12.4541 3.44536 10.6969 1.33594 8.00004 1.33594C5.30322 1.33594 3.54594 3.44536 3.54594 5.29934C3.54594 7.67287 2.33337 7.54847 2.33337 9.48294C2.50201 11.4261 4.24122 11.901 8.00004 11.901Z"
        stroke="#808D9E"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M9.59254 13.9062C8.68311 14.9161 7.26444 14.928 6.34631 13.9062"
        stroke="#808D9E"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
    <span
      v-if="notificationCount > 0"
      class="absolute top-0 right-0  h-2 w-2 rounded-full bg-ArtyClickRed flex items-center justify-center text-white text-[4px] leading-none"
    >
    {{ notificationCount }}
  </span>
  </div>
</template>

<script>
export default {
  name: "NotificationIcon",
  props: {
    notificationCount: {
      type: Number,
      default: 0,
    },
  },
};
</script>
