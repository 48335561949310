<template>
  <div class="bg-[#F8F8FB] rounded-xl px-6 py-5">
    <div class="h-full">
      <div class="space-y-6 mx-3 h-full">
        <div class="flex items-center justify-between">
          <h3 class="text-DarkJungle text-base font-bold">Documents</h3>
          <div
            class="text-center mt-auto"
            v-if="!isLoading && allDocuments.length > 0"
          >
            <router-link
              to="/patient/settings/report-and-statictics"
              class="text-OlsoGrey text-base font-semibold"
              >See all</router-link
            >
          </div>
        </div>

        <div>
          <div
            v-if="isLoading"
            class="w-full h-52 flex justify-center items-center"
          >
            <img
              src="../../../../assets/icons/loading-01.svg"
              alt="loading icon"
              class="animate-spin"
            />
          </div>

          <div
            v-else-if="!isLoading && allDocuments.length > 0"
            class="flex flex-col gap-3"
          >
            <div
              v-for="doc in allDocuments.slice(0, 3)"
              class="flex justify-between items-center pb-4 border-b-[0.85px] border-Platinum"
            >
              <div class="flex items-center gap-2">
                <img
                  src="../../../../assets/icons/file_present.svg"
                  alt="file icon"
                />
                <p
                  class="w-[154px] text-ellipsis overflow-hidden whitespace-nowrap"
                >
                  {{ doc.documents.split("documents/")[1] }}
                </p>
              </div>

              <a :href="formatDocumentUrl(doc.documents)" download>
                <img
                  src="../../../../assets/icons/file_download.svg"
                  alt="file download icon"
                />
              </a>
            </div>
          </div>

          <div v-else class="w-full h-64 flex justify-center items-center">
            <p class="text-base font-semibold">No documents</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue";
import { mapActions, mapGetters } from "@/hooks/mapStore";

const { "user/getAllDocumentUploads": getAllDocumentUploads } = mapActions();

const { "user/getAllDocumentUploads": allDocuments } = mapGetters();

const isLoading = ref(false);

const getAllDocuments = async () => {
  isLoading.value = true;
  try {
    await getAllDocumentUploads();
  } catch (error) {
    console.log("Error getting patient documents:", error);
  } finally {
    isLoading.value = false;
  }
};

getAllDocuments();

const formatDocumentUrl = (doc) => {
  return doc + "?download=true";
};
</script>
