import { createStore } from "vuex";
import auth from "./modules/auth";
import device from "./modules/device";
import notifications from "./modules/notifications";
import user from "./modules/user";
import blog from "./modules/blog";
import test from "./modules/test";
import appointment from "./modules/appointment";
import accountConnection from "./modules/accountConnection";
import twoFactorAuthentication from "./modules/twoFactorAuthentication";
import socket from "./modules/socket"

const store = createStore({
  modules: {
    auth,
    device,
    notifications,
    user,
    blog,
    test,
    appointment,
    accountConnection,
    twoFactorAuthentication,
    socket,
  },
});

export default store;
