<template>
  <AllModalsWrapper>
    <section class="w-full flex flex-row">
      <Sidebar />

      <div class="h-full w-full lg:w-[calc(100%-100px)] bg-WhiteLilac">
        <Header />

        <router-view></router-view>
      </div>
    </section>
  </AllModalsWrapper>
</template>

<script setup>
import { ref, provide, onMounted } from "vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
import { mapActions } from "@/hooks/mapStore";
import { toast } from "vue3-toastify";
import { initializeSocket } from "@/store/modules/socket";
import AllModalsWrapper from "@/components/main/AllModalsWrapper.vue";
import Header from "@/components/main/header/Header.vue";
import Sidebar from "@/components/main/sidebar/Sidebar.vue";

const store = useStore();

const isSidebarOpen = ref(false);

const toggleSidebar = () => {
  isSidebarOpen.value = !isSidebarOpen.value;
};

provide("isSidebarOpen", isSidebarOpen);
provide("toggleSidebar", toggleSidebar);

const {
  "notifications/fetchAllNotifications": fetchAllNotifications,
} = mapActions();

const initializeNotifications = async () => {
  try {
    await fetchAllNotifications();
  } catch (error) {
    toast.error("Error fetching notifications", { autoClose: 2000 });
  }

  // Get the userId from the store and initialize the socket
  const userId = store.getters["user/getPatientId"];
  if (userId) {
    initializeSocket(userId, store); // Passing userId to join the correct room
  } else {
    console.error("Failed to initialize socket: No userId found");
  }
};

onMounted(() => {
  initializeNotifications();
});
</script>
