<template>
  <div
    class="mx-auto px-2 lg:px-4 py-4 min-h-[calc(100vh-80px)] flex flex-col space-y-8 lg:space-y-0 lg:flex-row lg:space-x-10"
  >
    <div class="xl:mx-10 rounded-lg shadow-xl w-full bg-white p-4 md:p-10">
      <div class="bg-CatskillWhite">
        <div>
          <div
            class="flex flex-col space-y-6 md:space-y-0 md:flex-row md:justify-between items-center mb-4"
          >
            <h1 class="text-ResolutionBlue text-2xl font-bold mb-6">
              Blog Posts
            </h1>
            <router-link
              to="/admin/add-blog-post"
              class="w-fit bg-ResolutionBlue text-white border border-ResolutionBlue py-2 px-5 rounded-[40px] text-center text-sm font-bold cursor-pointer hover:border-DodgerBlue hover:bg-DodgerBlue hover:text-white transition-all duration-300"
            >
              New Post
            </router-link>
          </div>
          <div
            v-if="isLoading"
            class="w-full h-64 flex justify-center items-center"
          >
            <img
              src="../../../../assets/icons/loading-01.svg"
              alt="loading icon"
              class="animate-spin"
            />
          </div>

          <div v-else-if="!isLoading && getBlogPosts?.posts?.length > 0">
            <div class="overflow-x-auto">
              <table class="w-full">
                <thead>
                  <tr>
                    <th
                      class="min-w-[40px] py-3 border-b border-gray-200 text-left text-sm font-bold text-[#25282B]"
                    >
                      S/N
                    </th>
                    <th
                      class="min-w-[130px] py-3 border-b border-gray-200 text-left text-sm font-bold text-[#25282B]"
                    >
                      Title
                    </th>
                    <th
                      class="min-w-[130px] py-3 border-b border-gray-200 text-left text-sm font-bold text-[#25282B]"
                    >
                      Type
                    </th>
                    <th
                      class="min-w-[60px] py-3 border-b border-gray-200 text-left text-sm font-bold text-[#25282B]"
                    >
                      Author
                    </th>

                    <th
                      class="min-w-[130px] py-3 border-b border-gray-200 text-left text-sm font-bold text-[#25282B]"
                    >
                      Date &amp; time
                    </th>
                    <th
                      class="min-w-[130px] py-3 border-b border-gray-200 text-left text-sm font-bold text-[#25282B]"
                    ></th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(post, index) in getBlogPosts?.posts"
                    :key="post.id"
                  >
                    <td
                      class="min-w-[40px] py-3 border-t border-gray-200 text-DavyGrey text-sm"
                    >
                      {{ index + 1 }}
                    </td>
                    <td
                      class="min-w-[100px] py-3 border-t border-gray-200 text-DavyGrey text-sm cursor-pointer"
                    >
                      {{ post?.title.substring(0, 50) + " ..." }}
                    </td>
                    <td
                      class="min-w-[60px] py-3 border-t border-gray-200 text-DavyGrey text-sm"
                    >
                      {{ post?.type }}
                    </td>
                    <td
                      class="min-w-[100px] py-3 border-t border-gray-200 text-DavyGrey text-sm cursor-pointer"
                    >
                      {{ post?.author }}
                    </td>
                    <td
                      class="min-w-[100px] py-3 border-t border-gray-200 text-DavyGrey text-sm"
                    >
                      {{ dateFormatter(post?.created_at) }} -
                      {{ timeFormatter(post?.created_at) }}
                    </td>
                    <td
                      class="space-x-1 min-w-[100px] py-3 border-t border-gray-200 text-DavyGrey text-sm"
                    >
                      <button
                        class="w-fit border border-ResolutionBlue py-0.5 px-4 rounded-3xl text-ResolutionBlue text-center text-[10px] font-bold cursor-pointer hover:border-DodgerBlue hover:bg-DodgerBlue hover:text-white transition-all duration-300"
                        @click="opentModal(post)"
                      >
                        View
                      </button>
                      <button
                        class="w-fit border border-ResolutionBlue py-0.5 px-4 rounded-3xl text-ResolutionBlue text-center text-[10px] font-bold cursor-pointer hover:border-DodgerBlue hover:bg-DodgerBlue hover:text-white transition-all duration-300"
                        @click="openEditModal(post)"
                      >
                        Edit
                      </button>
                      <button
                        class="w-fit border border-ArtyClickRed py-0.5 px-4 rounded-3xl text-ArtyClickRed text-center text-[10px] font-bold cursor-pointer hover:border-ArtyClickRed hover:bg-ArtyClickRed hover:text-white transition-all duration-300"
                        @click="openDeleteModal(post)"
                      >
                        Delete
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div
            v-else
            class="w-full h-64 flex flex-col space-y-3 justify-center items-center"
          >
            <p class="text-base font-semibold">No available blog post</p>
            <router-link
              to="/admin/add-blog-post"
              class="w-fit bg-ResolutionBlue text-white border border-ResolutionBlue py-2 px-5 rounded-[40px] text-center text-sm font-bold cursor-pointer hover:border-DodgerBlue hover:bg-DodgerBlue hover:text-white transition-all duration-300"
            >
              Add New Post
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, inject } from "vue";
import { mapActions, mapGetters } from "@/hooks/mapStore";
import { dateFormatter } from "@/utils/dateFormatter";
import { timeFormatter } from "@/utils/dateFormatter";

const { "blog/fetchBlogPosts": fetchBlogPosts } = mapActions();

const { "blog/getBlogPosts": getBlogPosts } = mapGetters();

const isLoading = ref(false);

const opentModal = inject("openBlogPostModal");
const openEditModal = inject("openEditBlogModal");
const openDeleteModal = inject("openDeleteBlogModal");

const getAllBlogPosts = async () => {
  isLoading.value = true;
  try {
    await fetchBlogPosts();
  } catch (error) {
    console.log("err", error);
  } finally {
    isLoading.value = false;
  }
};

getAllBlogPosts();
</script>

<script>
export default {
  name: "BlogPosts",
};
</script>
