<template>
  <ModalWrapper>
    <div
      class="bg-white w-full max-w-[1080px] flex flex-col gap-3.5 p-6 rounded-3xl md:p-[50px] md:gap-9 lg:rounded-[38px] relative"
    >
      <div class="w-full flex justify-between items-center">
        <h3 class="text-GunMetal text-base md:text-xl font-bold">
          Test Center
        </h3>
        <div
          class="w-6 h-6 cursor-pointer"
          @click="closeLinkedAccountTakeTestModal"
        >
          <img :src="Close" alt="close" class="w-full h-full" />
        </div>
      </div>

      <form class="w-full flex flex-col gap-6 md:flex-row">
        <div class="w-full space-y-6">
          <!-- Patient Name Field (Non-editable) -->
          <div>
            <label class="block text-xs font-normal text-SkyGrey"
              >Patient Name</label
            >
            <input
              type="text"
              :value="props.patientName"
              class="block w-full py-2 border-b border-BlueChalk text-sm font-bold text-DavyGrey bg-transparent"
              disabled
            />
          </div>

          <!-- Test Type Field (Non-editable) -->
          <div>
            <label class="block text-xs font-normal text-SkyGrey"
              >Test Type</label
            >
            <div
              class="w-full flex items-center justify-between mt-1 border-b border-BlueChalk bg-transparent"
            >
              <input
                type="text"
                v-model="selectedTestType"
                class="block w-full py-2 text-sm font-bold text-DavyGrey bg-transparent"
                disabled
              />
              <div class="w-4 h-4">
                <img
                  :src="DropdownArrow"
                  alt="dropdown arrow"
                  class="w-full h-full"
                />
              </div>
            </div>
          </div>
        </div>

        <!-- Device Selection Section -->
        <div class="w-full relative">
          <!-- If a device is selected, show the selected device view -->
          <div class="space-y-1 col-span-1" v-if="selectedDeviceView">
            <p class="text-sm font-bold text-OlsoGrey md:text-base">
              Selected Device
            </p>

            <div class="px-3.5 bg-WhiteLilac rounded-lg">
              <div
                class="flex justify-between items-center px-4 py-2.5 bg-white rounded-lg"
              >
                <div class="flex items-center gap-[18px]">
                  <div
                    class="w-14 h-[50px] border-[0.5px] border-SkyGrey rounded-sm"
                  >
                    <img
                      :src="selectedDeviceImage"
                      alt="Selected Device"
                      class="w-full h-full"
                    />
                  </div>
                  <div>
                    <p class="text-xs text-SkyGrey font-normal">
                      {{ selectedTestType }}
                    </p>
                    <p class="font-bold text-sm text-OlsoGrey">
                      {{ selectedDeviceView }}
                    </p>
                  </div>
                </div>
                <div
                  class="w-[18px] h-[18px] cursor-pointer"
                  @click="clearSelectedDevice"
                >
                  <img
                    src="@/assets/icons/close.svg"
                    alt="Clear Selection"
                    class="w-full h-full"
                  />
                </div>
              </div>
            </div>
          </div>

          <!-- If no device is selected, show the device selection interface -->
          <transition name="fade">
            <div v-if="!selectedDeviceView">
              <div
                class="w-full flex items-center justify-between px-2.5 py-4 border border-BlueChalk text-sm font-bold text-DavyGrey bg-transparent rounded cursor-pointer"
                @click="toggleDeviceSelection"
              >
                <p>Take a new test</p>
                <div class="w-4 h-4">
                  <img
                    :src="DropdownArrow"
                    alt="dropdown arrow"
                    class="w-full h-full"
                  />
                </div>
              </div>

              <transition name="fade">
                <div
                  v-if="showDeviceSelection"
                  class="absolute top-16 left-0 right-0 w-full flex flex-col gap-6 p-[18px] bg-white border border-BlueChalk text-sm text-DarkJungleGreen font-normal rounded-lg z-10"
                >
                  <!-- Patient's Devices -->
                  <section class="w-full space-y-2">
                    <div class="text-sm font-bold">
                      {{ props.patientName }}'s Devices
                    </div>
                    <div
                      v-for="device in patientDevices"
                      :key="device.id"
                      class="flex items-center gap-4 cursor-pointer"
                      @click="
                        selectDevice(
                          device.type,
                          device.patient.avatar_url,
                          device.id
                        )
                      "
                    >
                      <div class="w-[30px] h-[30px]">
                        <img
                          :src="device.patient.avatar_url"
                          alt="Device"
                          class="w-full h-full"
                        />
                      </div>
                      <p>{{ device.type }}</p>
                    </div>
                    <!-- If no devices, show fallback message -->
                    <div v-if="!patientDevices.length" class="text-center">
                      This patient has no linked devices found.
                    </div>
                  </section>

                  <!-- User's Devices -->
                  <section class="w-full space-y-2 mt-4">
                    <div class="text-sm font-bold">Your Device</div>
                    <div
                      v-for="device in yourDevices"
                      :key="device.id"
                      class="flex items-center gap-4 cursor-pointer"
                      @click="
                        selectDevice(
                          device.type,
                          device.patient.avatar_url,
                          device.id
                        )
                      "
                    >
                      <div class="w-[30px] h-[30px]">
                        <img
                          :src="device.patient.avatar_url"
                          alt="Device"
                          class="w-full h-full"
                        />
                      </div>
                      <p>{{ device.type }}</p>
                    </div>
                    <!-- If no devices, show fallback message -->
                    <div v-if="!yourDevices.length" class="text-center">
                      You have no linked devices found.
                    </div>
                  </section>
                </div>
              </transition>
            </div>
          </transition>
        </div>
      </form>

      <!-- Cancel Button & Run Test Button -->
      <div class="flex items-center gap-2.5 self-center">
        <button
          @click="closeLinkedAccountTakeTestModal"
          class="px-[52px] py-3 border border-ResolutionBlue text-ResolutionBlue rounded-full text-sm font-semibold"
        >
          Cancel
        </button>

        <button
          @click="handleRunTestClick"
          class="px-[52px] py-3 bg-ResolutionBlue text-white rounded-full text-sm font-semibold"
          :disabled="isLoading"
        >
          <span v-if="isLoading">
            <LoadingSpinner />
          </span>
          <span v-else>Run Test</span>
        </button>
      </div>
    </div>
  </ModalWrapper>
</template>

<script setup>
import { ref, inject, onMounted } from "vue";
import { useStore } from "vuex";
import ModalWrapper from "./ModalWrapper.vue";
import DropdownArrow from "@/assets/icons/down_caret.svg";
import Close from "@/assets/icons/close.svg";
import LoadingSpinner from "@/components/icons/LoadingSpinner.vue";

const store = useStore();

const props = defineProps({
  patientName: String,
  patientId: String,
});

const closeLinkedAccountTakeTestModal = inject(
  "closeLinkedAccountTakeTestModal"
);
const openTestInstructionModal = inject("openTestInstructionModal");
const openManualTestModal = inject("openManualTestModal");
const updateSelectedDevice = inject("updateSelectedDevice");

const selectedPatientId = ref("");
const isLoading = ref(false);
const selectedTestType = ref("Glucose");
const selectedDeviceView = ref(null);
const selectedDeviceImage = ref(null);
const selectedDeviceId = ref(null);
const showDeviceSelection = ref(false);
const showDeviceOptions = ref(false);
const patientDevices = ref([]); // To hold the patient's devices
const yourDevices = ref([]); // To hold the user's own devices

onMounted(async () => {
  console.log("Patient Name:", props.patientName);
  console.log("Patient ID passed to modal:", props.patientId); // Check if the patientId is correct

  // Fetch linked patient devices
  if (props.patientId) {
    await store.dispatch("test/getLinkedPatientDevices", props.patientId);
    patientDevices.value = store.getters["test/getLinkedPatientDevices"];
    console.log("Patient Devices:", patientDevices.value);
  } else {
    console.error("No patient ID provided");
  }

  await store.dispatch("test/getMedicalDevices");
  yourDevices.value = store.getters["test/getMedicalDevices"];
  console.log("Your Devices:", yourDevices.value); // Check if devices are fetched
});

// Function to toggle the device selection dropdown
const toggleDeviceSelection = () => {
  showDeviceSelection.value = !showDeviceSelection.value;
  showDeviceOptions.value = false;
};

// Function to select a device
const selectDevice = (deviceName, deviceImage, deviceId) => {
  selectedDeviceView.value = deviceName;
  selectedDeviceImage.value = deviceImage;
  selectedDeviceId.value = deviceId;
  showDeviceSelection.value = false;
  updateSelectedDevice(deviceName, deviceImage, selectedTestType.value);
};

// Handle Run Test button click
const handleRunTestClick = async () => {
  if (selectedDeviceView.value) {
    isLoading.value = true;
    try {
      await store.dispatch(
        "test/getSingleMedicalDevice",
        selectedDeviceId.value
      );
      openTestInstructionModal();
    } catch (error) {
      console.error("Failed to fetch the medical device.");
    } finally {
      isLoading.value = false;
    }
  } else {
    console.error("Please select a device before running the test.");
  }
};
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease;
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
