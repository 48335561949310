<template>
  <div class="flex items-center justify-between border-b pb-3">
    <div class="flex items-center cursor-pointer">
      <div class="flex-shrink-0 w-10 h-10">
        <img
          class="w-full h-full rounded-full object-cover"
          :src="
            connections_received?.patient_sender?.avatar_url ||
            connections_received?.health_centre?.avatar_url ||
            noAvatar
          "
          alt=""
        />
      </div>
      <div class="ml-3">
        <p class="font-light whitespace-no-wrap">
          <span class="font-bold text-ResolutionBlue">{{
            connections_received?.patient_sender?.full_name ||
            connections_received?.health_centre.name
          }}</span>
          invited you to be linked as their
          <span class="font-bold">{{
            connections_received?.relationship
          }}</span>
        </p>
        <div
          class="flex items-center space-x-6 text-SantaGrey font-extralight text-sm"
        >
          <span> {{ dateFormatter_2(connections_received?.created_at) }}</span>
          <div class="flex items-center">
            <span>・</span>
            <span>{{ timeFormatter_2(connections_received?.created_at) }}</span>
          </div>
        </div>
      </div>
    </div>

    <div class="flex items-center space-x-2">
      <button
        @click="acceptRequest"
        class="bg-ResolutionBlue text-white px-3 py-2 rounded-3xl text-xs font-medium w-16 flex justify-center items-center"
      >
        <span v-if="isLoading" class="pl-3">
          <LoadingSpinner />
        </span>
        <span v-else>Accept</span>
      </button>
      <button
        @click="denyRequest"
        class="border border-ArtyClickRed px-3 py-2 rounded-3xl text-xs font-medium hover:border-ArtyClickRed hover:bg-ArtyClickRed hover:text-white transition-all duration-300"
      >
        <span v-if="loading" class="pl-3">
          <LoadingSpinner />
        </span>
        <span v-else>Decline</span>
      </button>
    </div>
  </div>
</template>
<script setup>
import { ref } from "vue";
import { dateFormatter_2 } from "@/utils/dateFormatter";
import { timeFormatter_2 } from "@/utils/dateFormatter";
import LoadingSpinner from "@/components/icons/LoadingSpinner.vue";
import { mapActions } from "@/hooks/mapStore";

const noAvatar =
  "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png";

import { toast } from "vue3-toastify";

const props = defineProps(["connections_received"]);
// console.log("connections_received vv", props.connections_received);

const {
  "accountConnection/acceptPatientRequest": acceptPatientRequest,
  "accountConnection/fetchPatientRequests": fetchPatientRequests,
} = mapActions();

const isLoading = ref(false);
const loading = ref(false);

const acceptRequest = async () => {
  try {
    isLoading.value = true;
    const aceptRequestData = await acceptPatientRequest({
      response: "accept",
      id: props.connections_received.id,
    });
    if (aceptRequestData) {
      console.log("acceptRequest 11", aceptRequestData);
      toast.success("Request accepted successfully", {
        autoClose: 3000,
      });
      fetchPatientRequests();
      console.log("acceptRequest acceptRequest:", aceptRequestData);
    }
  } catch (error) {
    const errorMessage = error.response
      ? error.response.data?.message
      : error?.message;
    if (typeof errorMessage === "string") {
      toast.error(errorMessage, {
        autoClose: 3000,
      });
    } else {
      toast.error("Error accepting request", {
        autoClose: 3000,
      });
    }
  } finally {
    isLoading.value = false;
  }
};

const denyRequest = async () => {
  try {
    loading.value = true;
    const denyRequestData = await acceptPatientRequest({
      response: "deny",
      id: props.connections_received.id,
    });
    if (denyRequestData) {
      console.log("denyRequest", denyRequestData);
      toast.success("Request denied successfully", {
        autoClose: 3000,
      });
      fetchPatientRequests();
      console.log("denyRequest denyRequest:", denyRequestData);
    }
  } catch (error) {
    const errorMessage = error.response
      ? error.response.data?.message
      : error?.message;
    if (typeof errorMessage === "string") {
      toast.error(errorMessage, {
        autoClose: 3000,
      });
    } else {
      toast.error("Error denying request", {
        autoClose: 3000,
      });
    }
  } finally {
    loading.value = false;
  }
};
</script>
