<template>
  <div class="flex flex-col h-full space-y-4">
    <div
      class="h-[40%] bg-ResolutionBlue text-white flex flex-row justify-between items-center border rounded-xl px-10 py-10"
    >
      <div class="space-y-4">
        <p class="text-md md:text-lg font-semibold">Your Progress</p>
        <p class="text-sm font-thin">Monthly Progress Report</p>
      </div>

      <div class="font-bold rounded-full border-[6px] border-Glaucous p-4">
        {{
          getPatientProgressScore === null
            ? "--"
            : getPatientProgressScore?.progress !== null
            ? getPatientProgressScore?.progress
            : "--"
        }}%

        <!-- {{
                  getPatientProgressScore?.progress !== null
                    ? getPatientProgressScore?.progress
                    : "--"
                }}% -->
      </div>
    </div>

    <div class="h-[60%] bg-BlueChalk space-y-6 border rounded-xl p-4">
      <div class="flex flex-row items-center space-x-8 h-full">
        <div
          class="w-5/12 bg-ResolutionBlue space-y-1 text-white h-full flex flex-col rounded-xl justify-center items-center"
        >
          <p class="text-xs">Health Score</p>
          <p class="text-3xl font-bold">
            {{
              getPatientProgressScore === null
                ? "--"
                : getPatientProgressScore?.health_score !== null
                ? getPatientProgressScore?.health_score
                : "--"
            }}
          </p>
        </div>

        <div class="w-7/12">
          <div class="grid grid-cols-2 gap-3">
            <div>
              <p class="text-xs text-OlsoGrey">Height</p>
              <p class="text-xl font-semibold">
                {{
                  getPatientProfile?.person?.height
                    ? getPatientProfile?.person?.height
                    : "--"
                }}
                {{ getPatientProfile?.person?.height_units }}
              </p>
            </div>

            <div>
              <p class="text-xs text-OlsoGrey">Weight</p>
              <p class="text-xl font-semibold">
                {{
                  getPatientProfile?.person?.weight
                    ? getPatientProfile?.person?.weight
                    : "--"
                }}
                {{ getPatientProfile?.person?.weight_units }}
              </p>
            </div>

            <div>
              <p class="text-xs text-OlsoGrey">Blood Group</p>
              <p class="text-xl font-semibold">
                {{
                  getPatientProfile?.person?.blood_group
                    ? getPatientProfile?.person?.blood_group
                    : "--"
                }}
              </p>
            </div>

            <div>
              <p class="text-xs text-OlsoGrey">Age</p>
              <p class="text-xl font-semibold">
                {{
                  getPatientProfile?.person?.age
                    ? getPatientProfile?.person?.age
                    : "--"
                }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { mapGetters } from "@/hooks/mapStore";

const {
  "user/getPatientProfile": getPatientProfile,
  "user/getPatientProgressScore": getPatientProgressScore,
} = mapGetters();
</script>
