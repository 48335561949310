import axios from "@/api";

const state = {
  allAppointments: [],
  upcomingAppointments: [],
  completedAppointments: [],
  expiredAppointments: [],
  cancelledAppointments: [],
};

const getters = {
  getAppointmentsByStatus: (state) => (status) => {
    if (status === 'all') return state.allAppointments;
    if (status === 'pending') return state.upcomingAppointments;
    if (status === 'completed') return state.completedAppointments;
    if (status === 'expired') return state.expiredAppointments;
    if (status === 'cancelled') return state.cancelledAppointments;
    return [];
  },
};

const mutations = {
  setAppointments(state, { status, appointments }) {
    if (status === 'all') state.allAppointments = appointments;
    if (status === 'pending') state.upcomingAppointments = appointments;
    if (status === 'completed') state.completedAppointments = appointments;
    if (status === 'expired') state.expiredAppointments = appointments;
    if (status === 'cancelled') state.cancelledAppointments = appointments;
  },
};

const actions = {
  async fetchPatientAppointments({ commit, getters }, status = "all") {
    // Check if we already have appointments for this status
    // const existingAppointments = getters.getAppointmentsByStatus(status);
    // if (existingAppointments.length > 0) {
    //   return;
    // }

    try {
      const response = await axios.get(`/data/appointments`, {
        params: {
          status: status !== "all" ? status : undefined,
        },
      });

      let filteredAppointments = response.data.appointments;

      // Special handling for upcoming appointments
      if (status === 'pending') {
        filteredAppointments = response.data.appointments.filter(
          appointment => appointment.status === 'Pending' || appointment.status === 'Rescheduled'
        );
      }

      commit("setAppointments", {
        status,
        appointments: filteredAppointments,
      });
    } catch (error) {
      throw error;
    }
  },


  async bookAppointment({ dispatch }, payload) {
    try {
      await axios.post(
        `/data/appointment/test_centre/${payload.id}`,
        payload.data
      );
      // After successful booking, fetch all appointments to update the UI
      await dispatch('fetchPatientAppointments', 'all');
    } catch (error) {
      throw error;
    }
  },

  async getAvailableTimes({ commit }, { healthCentreId, date }) {
    console.log("Fetching available times for health center:", healthCentreId, "on date:", date);
    try {
      const response = await axios.get(
        `/data/appointment/appointment_for_day/${healthCentreId}`, {
        params: { date }
      }
      );
      console.log("Available times fetched successfully:", response.data);
      return response.data.available_slots;
    } catch (error) {
      console.error("Error fetching available times:", error);
      throw error;
    }
  },


  async editAppointment({ dispatch }, payload) {
    try {
      await axios.put(
        `data/appointment/${payload.appointmentId}`,
        payload.data
      );

      // After successful editing, fetch all appointments to update the UI
      await dispatch('fetchPatientAppointments', 'all');
    } catch (error) {
      throw error;
    }
  },

  async cancelAppointment({ dispatch }, payload) {
    try {
      const response = await axios.put(
        `data/appointment/delete/${payload.id}`,
        {
          reason_for_cancellation: payload.reason,
        }
      );
      // After successful cancellation, fetch all appointments to update the UI
      await dispatch('fetchPatientAppointments', 'all');
      return response.data.message;
    } catch (error) {
      throw error;
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
