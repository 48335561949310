<template>
  <div :class="containerClasses">
    <div :class="searchBarClasses">
      <div class="flex items-center w-full">
        <img
          v-if="showIcon && iconSrc"
          :src="iconSrc"
          alt="Search Icon"
          class="mr-2"
        />
        <input
          v-model="searchKeyword"
          @input="handleSearch"
          @focus="inputFocus = true"
          @blur="inputFocus = false"
          type="text"
          :class="inputClasses"
          :placeholder="placeholder"
        />
      </div>
    </div>

    <!-- Search Results Section -->
    <div
      v-if="
        (searchKeyword !== userName || searchResults.length > 0) &&
        !patientSelected
      "
      class="text-sm"
    >
      <p v-if="searching" class="border p-4 rounded-2xl h-52">Searching...</p>
      <div v-else-if="searchResults.length > 0">
        <div class="border p-4 rounded-2xl h-52 overflow-auto space-y-1">
          <div v-for="(result, index) in searchResults" :key="index">
            <div
              class="flex items-center cursor-pointer hover:bg-WhiteLilac hover:rounded-lg p-1"
              @click="emitPatientSelect(result)"
            >
              <div class="flex-shrink-0 w-7 h-7">
                <img
                  class="w-full h-full rounded-full object-cover"
                  :src="
                    result?.person?.avatar_url || result?.avatar_url || noAvatar
                  "
                  alt="Profile Picture"
                />
              </div>
              <div class="ml-3">
                <p class="font-bold text-DavyGrey whitespace-no-wrap">
                  {{ result?.full_name || result.name }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        v-else-if="!searching && searchResults.length === 0 && !patientSelected"
        class="border p-4 rounded-2xl h-52"
      >
        No patient found.
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, watch } from "vue";
import { mapActions } from "@/hooks/mapStore";
import { toast } from "vue3-toastify";

const emit = defineEmits(["selectPatient"]);

const { "user/searchPatient": searchPatient } = mapActions();

const props = defineProps({
  iconSrc: {
    type: String,
    default: null, 
  },
  showIcon: {
    type: Boolean,
    default: true,
  },
  containerClasses: {
    type: String,
    default: "space-y-6 md:w-full lg:w-[70%] xl:w-[40%] mb-6",
  },
  searchBarClasses: {
    type: String,
    default:
      "flex flex-row items-center justify-between border rounded-md px-2 py-2.5",
  },
  inputClasses: {
    type: String,
    default: "bg-transparent w-full focus:outline-0 px-3 py-1 text-sm",
  },
  placeholder: {
    type: String,
    default: "Search for patient by username",
  },
  userName: {
    type: String,
    default: "",
  },
  restrictHealthCentre: {
    type: Boolean,
    default: false, // Default is false, so no restriction unless specified
  },
});

const searchKeyword = ref(props.userName);
const searchResults = ref([]);
const patientSelected = ref(false);
const searching = ref(false);

const noAvatar =
  "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png";

let timerId;

// Update the searchKeyword when userName prop changes
watch(
  () => props.userName,
  (newVal) => {
    searchKeyword.value = newVal;
  },
  { immediate: true }
);


const handleSearch = async () => {
  if (searchKeyword.value === props.userName && props.userName) {
    // Skip search when the input is still the user's name
    return;
  }

  searching.value = true;
  clearTimeout(timerId);
  timerId = setTimeout(async () => {
    try {
      const response = await searchPatient({ username: searchKeyword.value });
      searchResults.value = response.results;
      searching.value = false;
      patientSelected.value = false; // Reset patientSelected when performing a search
    } catch (error) {
      console.error("Error searching users:", error);
      searching.value = false;
    }
  }, 1000);
};

const emitPatientSelect = (patient) => {
  // Check if 'restrictHealthCentre' is enabled and the result is a HealthCentre
  if (props.restrictHealthCentre && patient.type === "HealthCentre") {
    // Trigger a toast notification 
    toast.error("You cannot take a test for a Health Centre.", "error");

    // Delay clearing the search results to prevent "No patient found" from appearing
    setTimeout(() => {
      patientSelected.value = false;
    }, 5000);

    return;
  }

  // Normal patient selection flow
  searchKeyword.value = patient.full_name || patient.name;
  searchResults.value = [];
  patientSelected.value = true;

  // Emit the selected patient back to the parent component
  emit("selectPatient", patient);
};
</script>
