<template>
  <div class="border rounded-[20px] px-10 py-6 space-y-4">
    <div class="flex items-center space-x-6">
      <div class="w-24 h-24 border rounded-full">
        <div v-if="patientDetails?.avatar_url">
          <img
            style="
              width: 6rem;
              height: 6rem;
              border-radius: 50%;
              object-fit: cover;
            "
            :src="patientDetails.avatar_url"
            alt=""
          />
        </div>
        <div v-else></div>
      </div>
      <div>
        <p class="text-DarkJungle font-bold md:text-xl">
          {{ patientDetails?.full_name }}
        </p>
        <div class="text-sm">
          <span>{{
            patientDetails?.username ? patientDetails?.username : "--"
          }}</span>
          <span class="text-OlsoGrey"> | </span
          ><span>
            {{
              patientDetails?.username ? patientDetails?.username : "--"
            }}</span
          >
        </div>
        <p class="text-OlsoGrey text-sm">{{ patientDetails?.email }}</p>
      </div>
    </div>

    <div class="space-y-4">
      <div class="grid grid-cols-3 gap-4 bg-GhostWhite rounded-2xl px-6 py-4">
        <div>
          <p class="text-SkyGrey text-xs">Gender</p>
          <p class="text-Mirage text-sm font-bold">
            {{ patientDetails?.gender ? patientDetails?.gender : "--" }}
          </p>
        </div>
        <div>
          <p class="text-SkyGrey text-xs">Age</p>
          <p class="text-Mirage text-sm font-bold">
            {{ patientDetails?.age ? patientDetails?.age : "--" }}
          </p>
        </div>
        <div>
          <p class="text-SkyGrey text-sm">Relationship</p>
          <p class="text-Mirage text-sm font-bold">
            {{
              linkedAccount?.relationship ? linkedAccount?.relationship : "--"
            }}
          </p>
        </div>
        <div>
          <p class="text-SkyGrey text-sm">Phone Number</p>
          <p class="text-Mirage text-sm font-bold">
            {{ patientDetails?.phone_number ? patientDetails?.phone_number : "--" }}
          </p>
        </div>

        <div class="">
          <p class="text-SkyGrey text-sm">Address</p>
          <p class="text-Mirage text-sm font-bold">
            {{ patientDetails?.address ? patientDetails?.address : "--" }}
          </p>
        </div>
      </div>

      <div class="bg-GhostWhite rounded-2xl px-8 py-5">
        <p class="text-Mirage text-sm font-bold mb-4">Emergency Contact</p>
        <div class="grid grid-cols-3 gap-4">
          <div>
            <p class="text-SkyGrey text-sm">Name</p>
            <p class="text-Mirage text-sm font-bold">
              {{
                patientDetails?.emergency_contact_name
                  ? patientDetails?.emergency_contact_name
                  : "--"
              }}
            </p>
          </div>
          <div>
            <p class="text-SkyGrey text-sm">Gender</p>
            <p class="text-Mirage text-sm font-bold">
              {{
                patientDetails?.emergency_contact_gender
                  ? patientDetails?.emergency_contact_gender
                  : "--"
              }}
            </p>
          </div>
          <div>
            <p class="text-SkyGrey text-sm">Relationship</p>
            <p class="text-Mirage text-sm font-bold">
              {{
                patientDetails?.emergency_contact_relationship
                  ? patientDetails?.emergency_contact_relationship
                  : "--"
              }}
            </p>
          </div>
          <div class="col-span-3">
            <p class="text-SkyGrey text-sm">Phone Number</p>
            <p class="text-Mirage text-sm font-bold">
              {{
                patientDetails?.emergency_contact_phone_number
                  ? patientDetails?.emergency_contact_phone_number
                  : "--"
              }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { watch } from 'vue';

const props = defineProps({
  patientDetails: {
    type: Object,
    required: true,
  },
  linkedAccount: {
    type: Object,
    required: true,
  },
});


</script>

<script>
export default {
  name: "LinkedAccountProfileDetails",
};
</script>
