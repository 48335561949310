<template>
  <ModalWrapper>
    <div
      class="max-w-[724px] w-full p-6 md:p-12 rounded-[48px] bg-white relative"
    >
      <div class="flex justify-between items-center mb-6">
        <h3 class="text-ResolutionBlue text-xl font-bold">
          {{ selectedTestType }}
        </h3>
        <div class="cursor-pointer" @click="closeManualTestModal">
          <span
            class="w-4 h-0.5 bg-transparent relative inline-block before:w-full before:h-full before:bg-black before:absolute before:left-0 before:top-0 before:rotate-45 after:w-full after:h-full after:bg-black after:absolute after:left-0 after:top-0 after:-rotate-45"
          ></span>
        </div>
      </div>

      <div
        class="max-w-md w-full mx-auto flex flex-col items-center gap-[30px]"
      >
        <!-- Test Value Section -->
        <div
          class="max-w-[414px] w-full h-[186px] border-2 border-ClearBlue flex justify-center items-center rounded-xl"
        >
          <div class="w-[134px] flex gap-3 justify-center items-center">
            <input
              v-model="testValue"
              :disabled="isAutoMode"
              type="text"
              class="input-with-dashes text-2xl font-bold text-center text-ResolutionBlue w-full bg-transparent border-none outline-none"
              placeholder="__"
            />

            <div class="relative">
              <button
                v-if="!isAutoMode"
                @click="toggleDropdown"
                class="flex items-center justify-center gap-1 py-1.5 px-4 rounded-full bg-ResolutionBlue text-xs text-white font-semibold"
              >
                {{ units }}
                <img
                  src="@/assets/icons/white-down-arrow.svg"
                  alt="unit dropdown"
                  class="ml-1 w-4 h-4"
                  :class="{ 'rotate-180': isDropdownOpen }"
                />
              </button>
              <span
                v-else
                class="flex items-center justify-center gap-1 py-1.5 px-4 rounded-full bg-ResolutionBlue text-xs text-white font-semibold"
              >
                {{ units }}
              </span>

              <!-- Custom dropdown menu -->
              <div
                v-if="!isAutoMode && isDropdownOpen"
                class="absolute top-full left-0 mt-1 w-full bg-white border border-BlueChalk rounded-lg shadow-lg z-50"
              >
                <button
                  v-for="option in unitOptions"
                  :key="option"
                  @click="selectUnit(option)"
                  class="block w-full text-left px-4 py-2 text-sm text-ResolutionBlue hover:bg-BlueChalk"
                  :class="{ 'bg-BlueChalk': units === option }"
                >
                  {{ option }}
                </button>
              </div>
            </div>
          </div>
        </div>

        <!-- Range Indicator -->
        <div class="w-full flex flex-col items-center">
          <!-- Range indicator and markers -->
          <!-- Labels above the range -->
          <div class="w-full flex justify-between text-xs text-MistBlue">
            <span>Low</span>
            <span>Normal</span>
            <span>Average High</span>
            <span>High</span>
          </div>
          <div class="w-full h-4 relative">
            <!-- Color bars -->
            <div
              class="absolute bottom-0 w-full h-2 rounded-full overflow-hidden flex"
            >
              <div class="w-[25%] h-full bg-ResolutionBlue"></div>
              <div class="w-[25%] h-full bg-AsignGreen"></div>
              <div class="w-[25%] h-full bg-OrangePeel"></div>
              <div class="w-[25%] h-full bg-ArtyClickRed"></div>
            </div>
            <!-- Vertical Markers -->
            <div
              class="absolute top-1 left-[25%] w-0.5 h-full bg-[#F43F5E]"
            ></div>
            <div
              class="absolute top-1 left-[50%] w-0.5 h-full bg-[#F43F5E]"
            ></div>
            <div
              class="absolute top-1 left-[75%] w-0.5 h-full bg-[#F43F5E]"
            ></div>
          </div>
        </div>

        <!-- Before/After Meal Selection -->
        <div
          class="flex justify-between items-center space-x-2.5 py-1 px-2 rounded-full bg-BlueChalk"
        >
          <button
            @click="setActiveTab('beforeMeal')"
            :disabled="isAutoMode"
            :class="[
              activeTab === 'beforeMeal'
                ? 'bg-AsignGreen text-white'
                : 'text-Gravel',
              'text-xs font-normal py-1.5 px-2.5 rounded-full',
              isAutoMode ? 'cursor-not-allowed' : 'cursor-pointer',
            ]"
          >
            Before meal
          </button>
          <button
            @click="setActiveTab('afterMeal')"
            :disabled="isAutoMode"
            :class="[
              activeTab === 'afterMeal'
                ? 'bg-AsignGreen text-white'
                : 'text-Gravel',
              'text-xs font-normal py-1.5 px-2.5 rounded-full',
              isAutoMode ? 'opacity-50 cursor-not-allowed' : 'cursor-pointer',
            ]"
          >
            After meal
          </button>
        </div>

        <!-- Date and Time Selection -->
        <div class="w-full flex items-center gap-3">
          <!-- Custom Date Input -->
          <div class="w-full cursor-pointer relative" @click="openDatePicker">
            <input
              type="text"
              :value="formattedDate"
              placeholder="Choose date"
              @click="openDatePicker"
              :disabled="isAutoMode"
              class="w-full py-5 px-2.5 bg-transparent border-b border-BlueChalk text-BluishGrey outline-none cursor-pointer"
              :class="isAutoMode ? 'cursor-not-allowed' : 'cursor-pointer'"
            />
            <img
              src="@/assets/icons/calendar-gray-icon.svg"
              alt="calendar icon"
              class="ml-2 w-5 h-5 absolute right-2 top-1/2 transform -translate-y-1/2 pointer-events-none"
            />
            <!-- Hidden Date Input -->
            <input
              type="date"
              ref="datePicker"
              v-model="selectedDate"
              :disabled="isAutoMode"
              class="w-full absolute inset-0 opacity-0 pointer-events-auto"
              :class="
                isAutoMode ? 'pointer-events-none' : 'pointer-events-auto'
              "
            />
          </div>

          <!-- Custom Time Input -->
          <div class="w-full cursor-pointer relative" @click="openTimePicker">
            <input
              type="text"
              :value="formattedTime"
              placeholder="Choose time"
              :disabled="isAutoMode"
              class="w-full py-5 px-2.5 bg-transparent border-b border-BlueChalk text-BluishGrey outline-none cursor-pointer"
              :class="isAutoMode ? 'cursor-not-allowed' : 'cursor-pointer'"
              @click="openTimePicker"
            />
            <img
              src="@/assets/icons/clock.svg"
              alt="clock icon"
              class="ml-2 w-5 h-5 absolute right-2 top-1/2 transform -translate-y-1/2 pointer-events-none"
            />
            <!-- Hidden Time Input -->
            <input
              type="time"
              ref="timePicker"
              v-model="selectedTime"
              :disabled="isAutoMode"
              class="w-full absolute inset-0 opacity-0 pointer-events-auto"
              :class="
                isAutoMode ? 'pointer-events-none' : 'pointer-events-auto'
              "
            />
          </div>
        </div>

        <!-- Cancel and Save Buttons -->
        <div class="flex justify-center items-center gap-4 mt-8">
          <button
            @click="closeManualTestModal"
            class="px-[52px] py-3 border border-ResolutionBlue text-ResolutionBlue rounded-full text-sm font-semibold"
          >
            Cancel
          </button>
          <button
            v-if="!isAutoMode"
            @click="handleSubmit"
            :disabled="isLoading || !isFormValid"
            :class="[
              'px-[52px] py-3 text-white rounded-full text-sm font-semibold',
              isLoading || !isFormValid
                ? 'bg-[#E0E0E0] cursor-not-allowed'
                : 'bg-ResolutionBlue cursor-pointer',
            ]"
          >
            <span v-if="isLoading">
              <LoadingSpinner />
            </span>
            <span v-else> Save </span>
          </button>
          <button
            v-else
            @click="handleDone"
            class="px-[52px] py-3 bg-ResolutionBlue text-white rounded-full text-sm font-semibold"
          >
            Done
          </button>
        </div>
      </div>
    </div>
  </ModalWrapper>
</template>

<script setup>
import {
  inject,
  ref,
  defineProps,
  watch,
  computed,
  onMounted,
  onUnmounted,
} from "vue";
import { useStore } from "vuex";
import { toast } from "vue3-toastify";
import ModalWrapper from "./ModalWrapper.vue";
import LoadingSpinner from "@/components/icons/LoadingSpinner.vue";

const props = defineProps({
  selectedTestType: String,
  patientId: String,
  existingData: Object, // Optional data passed from the socket event
});

const store = useStore();

const testValue = ref("");
const activeTab = ref("beforeMeal"); 
const selectedDate = ref(""); // Raw date value
const selectedTime = ref(""); // Raw time value
const formattedDate = ref(""); // Formatted date for display
const formattedTime = ref(""); // Formatted time for display
const isLoading = ref(false);
const units = ref("mg/dl");
const isDropdownOpen = ref(false);
const unitOptions = ["mg/dl", "mmol/l"];

const openTestSuccessfulModal = inject("openTestSuccessfulModal");
const closeManualTestModal = inject("closeManualTestModal");
const closeTakeTestModal = inject("closeTakeTestModal");



const handleDone = () => {
  closeManualTestModal();
  closeTakeTestModal();
  openTestSuccessfulModal();
};

// References for the hidden input elements
const datePicker = ref(null);
const timePicker = ref(null);

// Computed property to determine if we're in auto mode
const isAutoMode = computed(() => !!props.existingData);

// Method to change the active tab
const setActiveTab = (tab) => {
  if (!isAutoMode.value) {
    activeTab.value = tab;
  }
};

const toggleDropdown = () => {
  isDropdownOpen.value = !isDropdownOpen.value;
};

const selectUnit = (newUnit) => {
  if (units.value !== newUnit) {
    units.value = newUnit;
  }
  isDropdownOpen.value = false;
};

// Close dropdown when clicking outside
const closeDropdown = (event) => {
  if (isDropdownOpen.value && !event.target.closest(".relative")) {
    isDropdownOpen.value = false;
  }
};

onMounted(() => {
  if (props.existingData) {
    populateFormWithExistingData();
  }
  document.addEventListener("click", closeDropdown);
});

onUnmounted(() => {
  document.removeEventListener("click", closeDropdown);
});

const populateFormWithExistingData = () => {
  const data = props.existingData;
  testValue.value = data.glucose_level;
  activeTab.value = data.before_meal ? "beforeMeal" : "afterMeal";
  units.value = data.units || "mg/dl";

  if (data.check_time) {
    const dateTime = new Date(data.check_time);

    // Format date as YYYY-MM-DD for the date input
    selectedDate.value = dateTime.toISOString().split("T")[0];

    // Format time as HH:mm for the time input
    selectedTime.value = dateTime.toTimeString().slice(0, 5);

    handleDateChange();
    handleTimeChange();
  }
};

const isFormValid = computed(() => {
  return testValue.value && formattedDate.value && formattedTime.value;
});

// Method to open hidden date picker
const openDatePicker = () => {
  if (!isAutoMode.value) {
    datePicker.value.click();
  }
};

// Method to open hidden time picker
const openTimePicker = () => {
  if (!isAutoMode.value) {
    timePicker.value.click();
  }
};

// Method to handle date change
const handleDateChange = () => {
  if (selectedDate.value) {
    const date = new Date(selectedDate.value);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();
    formattedDate.value = `${day}-${month}-${year}`;
  }
};

// Method to handle time change
const handleTimeChange = () => {
  if (selectedTime.value) {
    const time = new Date(`1970-01-01T${selectedTime.value}:00`);
    const hours = time.getHours().toString().padStart(2, "0");
    const minutes = time.getMinutes().toString().padStart(2, "0");
    const seconds = time.getSeconds().toString().padStart(2, "0");
    formattedTime.value = `${hours}:${minutes}:${seconds}`;
  }
};

// Submit Test Result
const handleSubmit = async () => {
  if (!isFormValid.value) {
    toast.error("Please fill all fields before submitting.");
    return;
  }

  isLoading.value = true; 

  // Prepare payload for submission
  const payload = {
    Check_Time: `${formattedDate.value} ${formattedTime.value}`, // Combine date and time for Check_Time
    bloodSugar: testValue.value,
    beforeOrAfterMeals: activeTab.value === "beforeMeal" ? 1 : 0,
    units: units.value,
  };

  try {
    // Determine whether to submit for the current patient or a selected patient
    if (
      props.patientId &&
      props.patientId !== store.getters["user/getPatientId"]
    ) {
      // If a different patient is selected
      await store.dispatch("test/submitTestResultForPatient", {
        patientId: props.patientId,
        payload,
      });
      toast.success("Test result submitted for the selected patient.");
    } else {
      // If no other patient is selected, submit for the current user
      await store.dispatch("test/submitTestResult", payload);
      toast.success("Test result submitted successfully.");
    }

    await store.dispatch("user/sendProgressReport", props.patientId);
    toast.success("Progress report sent successfully.");

    closeManualTestModal();
    closeTakeTestModal();

    openTestSuccessfulModal();
  } catch (error) {
    // Check if the error response exists and contains a message
    const errorMessage =
      error.response && error.response.data && error.response.data.error
        ? error.response.data.error
        : "An error occurred while submitting the test result.";

    toast.error(errorMessage);
    console.error("Error submitting test result:", error);
  } finally {
    isLoading.value = false; 
  }
};

// Watch for changes in selectedDate and selectedTime
watch(selectedDate, handleDateChange);
watch(selectedTime, handleTimeChange);
</script>

<style scoped>
.input-with-dashes::placeholder {
  text-align: center;
  color: #011d7e;
}
</style>
