<template>
  <ModalWrapper>
    <div
      class="w-full h-full pt-40 pb-8 flex justify-center items-center overflow-y-scroll no-scrollbar"
    >
      <div class="w-[542px] bg-white rounded-[48px] pt-12 pb-16 px-12 relative">
        <div
          class="w-full h-full absolute left-0 top-0"
          @click="closeDropdowns"
        ></div>

        <div class="flex justify-between items-center mb-9">
          <div class="flex items-center gap-2 text-base font-bold">
            <h2 class="text-OlsoGrey flex items-center gap-2">
              Diagnostic Imaging Hub
              <span
                ><img
                  src="../../../../assets/icons/arrow_right_patient_info.svg"
                  alt=""
              /></span>
            </h2>
            <p class="text-ResolutionBlue">Upload</p>
          </div>

          <div @click="cancelUpload" class="cursor-pointer">
            <span
              class="w-4 h-0.5 bg-transparent inline-block relative before:absolute before:w-full before:h-full before:bg-DarkJungleGreen before:left-0 before:-top-0 before:rounded-sm before:-rotate-45 after:absolute after:w-full after:h-full after:bg-DarkJungleGreen after:left-0 after:top-0 after:rounded-sm after:rotate-45"
            ></span>
          </div>
        </div>

        <form @submit.prevent="handleSubmit">
          <div class="flex flex-col gap-6">
            <div class="relative">
              <label for="procedure" class="text-BluishGrey text-xs"
                >Select Procedure</label
              >
              <input
                type="text"
                id="procedure"
                class="w-full outline-none border-b border-b-BlueChalk text-DarkJungle text-sm font-bold"
                @focus="openProceduresDropdown"
                :value="procedure"
                readonly
              />
              <img
                src="../../../../assets/icons/down_caret.svg"
                alt="down caret icon"
                class="absolute top-[60%] -translate-y-1/2 right-0 cursor-pointer"
                @click="openProceduresDropdown"
              />
              <ProdecuresDropdown
                v-show="showProceduresDropdown"
                :procedures="procedures"
                @setProcedure="selectProcedure"
              />
            </div>

            <div class="relative">
              <label for="body-part" class="text-BluishGrey text-xs"
                >Body Part</label
              >
              <input
                type="text"
                id="body-part"
                class="w-full outline-none border-b border-b-BlueChalk text-DarkJungle text-sm font-bold"
                @focus="openBodyPartsDropdown"
                :value="bodyPart"
                readonly
              />
              <img
                src="../../../../assets/icons/down_caret.svg"
                alt="down caret icon"
                class="absolute top-[60%] -translate-y-1/2 right-0 cursor-pointer"
                @click="openBodyPartsDropdown"
              />
              <ProdecuresDropdown
                v-show="showBodyPartsDropdown"
                :procedures="bodyParts"
                @setProcedure="selectBodyPart"
              />
            </div>

            <div class="relative">
              <label for="hospital" class="text-BluishGrey text-xs"
                >Hospital/Lab</label
              >
              <input
                type="text"
                id="hospital"
                class="w-full outline-none border-b border-b-BlueChalk text-DarkJungle text-sm font-bold"
                v-model="hospital"
                placeholder="Enter hospital or lab name"
              />
            </div>

            <div class="relative">
              <textarea
                name="notes"
                placeholder="Add your notes here (Optional)"
                class="w-full h-[106px] resize-none py-4 px-[14px] border border-Platinum outline-Platinum focus:border-Platinum rounded-lg placeholder:text-MistBlue placeholder:text-sm"
                v-model="notes"
              ></textarea>
            </div>

            <label
              for="upload"
              class="relative p-6 bg-AquaHaze rounded-[10px] border-[0.85px] border-ClearBlue cursor-pointer"
            >
              <div class="flex items-center gap-5">
                <div class="p-1">
                  <img
                    src="../../../../assets/icons/photo.svg"
                    alt="photo icon"
                  />
                </div>

                <div class="text-sm">
                  <p class="text-ResolutionBlue font-semibold">
                    Drag/Drop file here. Tap here to upload file <br />
                  </p>
                  <p class="text-xs font-medium">
                    (.pdf, .txt, .docx, .png, .jpeg, .gif)
                  </p>
                  <p v-if="fileName" class="text-center font-semibold">
                    {{ fileName }}
                  </p>
                </div>
              </div>
              <input
                type="file"
                name="upload"
                id="upload"
                accept="application/pdf,.csv,text/plain,image/jpeg,image/png,image/gif"
                class="hidden absolute left-0 top-0 w-full h-full rounded-[10px] border-[0.85px] bg-red-200"
                @input="handleFile"
              />
            </label>
          </div>

          <div class="mt-20 relative">
            <button
              :disabled="isLoading"
              type="submit"
              class="py-[13px] px-[52px] rounded-[44px]"
              :class="
                isAllSelected
                  ? 'bg-ResolutionBlue text-white'
                  : 'bg-Geyser text-MistBlue'
              "
            >
              {{ isLoading ? "Uploading..." : "Upload" }}
            </button>
          </div>
        </form>
      </div>
    </div>
  </ModalWrapper>
</template>

<script>
import { computed, inject, ref } from "vue";
import ModalWrapper from "@/components/main/admin/testCenter/modals/ModalWrapper.vue";
import ProdecuresDropdown from "../dropdowns/ProdecuresDropdown.vue";
import { mapActions } from "@/hooks/mapStore";
import { toast } from "vue3-toastify";
import { procedures, bodyParts } from "@/utils/proceduresAndBodyPartsData";

export default {
  name: "ReportAndStatisticsModal",
  components: {
    ModalWrapper,
    ProdecuresDropdown,
  },
  setup() {
    const closeReportAndStatisticsModal = inject(
      "closeReportAndStatisticsModal"
    );
    const openFileUploadSuccessfulModal = inject(
      "openFileUploadSuccessfulModal"
    );

    const {
      "user/uploadDocument": uploadDocument,
      "user/getAllDocumentUploads": getAllDocumentUploads,
    } = mapActions();

    const getAllDocuments = async () => {
      try {
        await getAllDocumentUploads();
      } catch (error) {
        console.log("err", error);
      }
    };

    const showProceduresDropdown = ref(false);
    const showBodyPartsDropdown = ref(false);
    const procedure = ref("");
    const bodyPart = ref("");
    const notes = ref("");
    const file = ref(null);
    const fileName = ref("");
    const hospital = ref("");
    const isLoading = ref(false);

    const isAllSelected = computed(
      () => !!(procedure.value && bodyPart.value && file.value)
    );

    const openProceduresDropdown = () => {
      showProceduresDropdown.value = true;
      showBodyPartsDropdown.value = false;
    };

    const openBodyPartsDropdown = () => {
      showBodyPartsDropdown.value = true;
      showProceduresDropdown.value = false;
    };

    const selectProcedure = (value) => {
      procedure.value = value;
      showProceduresDropdown.value = false;
    };

    const selectBodyPart = (value) => {
      bodyPart.value = value;
      showBodyPartsDropdown.value = false;
    };

    const closeDropdowns = () => {
      showProceduresDropdown.value = false;
      showBodyPartsDropdown.value = false;
    };

    const handleFile = (e) => {
      try {
        // console.log("file:", e.target.files);
        file.value = e.target.files[0];
        fileName.value = e.target.files[0].name;
      } catch (error) {
        console.log({ error });
      }
    };

    const handleSubmit = async () => {
      const data = {
        procedure: procedure.value,
        body_part: bodyPart.value,
        note: notes.value,
        documents: file.value,
        hospital: hospital.value,
      };

      if (isAllSelected.value) {
        try {
          isLoading.value = true;
          await uploadDocument(data);
          cancelUpload();
          openFileUploadSuccessfulModal();
          getAllDocuments();
        } catch (error) {
          if (error?.response?.data?.message) {
            toast.error(error.response.data.message, {
              autoClose: 2000,
            });
          } else if (error?.message) {
            toast.error(error.message, {
              autoClose: 2000,
            });
          } else {
            toast.error("Error uploading document", {
              autoClose: 2000,
            });
          }
        } finally {
          isLoading.value = false;
        }
      } else {
        toast.info("Fill in required fields", {
          autoClose: 2000,
        });
      }
    };

    const cancelUpload = () => {
      procedure.value = "";
      bodyPart.value = "";
      notes.value = "";
      file.value = null;
      fileName.value = "";
      closeDropdowns();
      closeReportAndStatisticsModal();
    };

    return {
      showProceduresDropdown,
      showBodyPartsDropdown,
      openProceduresDropdown,
      openBodyPartsDropdown,
      closeDropdowns,
      procedure,
      procedures,
      selectProcedure,
      selectBodyPart,
      bodyPart,
      bodyParts,
      notes,
      fileName,
      handleFile,
      cancelUpload,
      isAllSelected,
      handleSubmit,
      isLoading,
      hospital,
    };
  },
};
</script>
