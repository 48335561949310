<template>
  <div
    class="fixed top-0 left-0 h-screen w-full bg-Dawn/20 backdrop-blur-sm flex justify-center overflow-y-scroll no-scrollbar z-[100000]"
  >
    <div
      class="w-full max-w-[500px] bg-white rounded-[38px] p-6 md:p-10 lg:p-12 m-auto relative"
    >
      <div>
        <div class="flex justify-end items-center mb-6">
          <div
            class="self-start cursor-pointer"
            @click="closeUnlinkPatientModal"
          >
            <span
              class="w-5 h-0.5 bg-transparent inline-block relative before:absolute before:w-full before:h-full before:bg-GunMetal before:left-0 before:-top-0 before:rounded-sm before:-rotate-45 after:absolute after:w-full after:h-full after:bg-GunMetal after:left-0 after:top-0 after:rounded-sm after:rotate-45"
            ></span>
          </div>
        </div>
        <div class="space-y-4">
          <h2 class="font-bold pb-[8px] text-xl w-full">
            Are you sure you want to unlink this contact?
          </h2>
          <p class="text-center font-normal text-xs">
            You cannot undo this action after you unlink
          </p>
          <div class="flex justify-center space-x-3">
            <button
              type="button"
              class="bg-white text-ResolutionBlue border border-ResolutionBlue rounded-[44px] text-sm font-semibold py-[13px] px-[52px]"
              @click="closeUnlinkPatientModal"
            >
              Cancel
            </button>
            <button
              class="w-40 border text-center text-[10px] cursor-pointer bg-ArtyClickRed text-white rounded-[44px] text-sm font-semibold py-[13px] px-[52px]"
              @click="unlinkPatient"
            >
              <span v-if="isLoading">
                <LoadingSpinner />
              </span>
              <span v-else>Unlink</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, inject } from "vue";
import { mapActions, mapGetters } from "@/hooks/mapStore";
import { toast } from "vue3-toastify";
import LoadingSpinner from "@/components/icons/LoadingSpinner.vue";

const props = defineProps(["account"]);

const {
  "accountConnection/unlinkPatientAccount": unlinkPatientAccount,
  "accountConnection/fetchPatientRequests": fetchPatientRequests,
} = mapActions();

const closeUnlinkPatientModal = inject("closeUnlinkPatientModal");
const isLoading = ref(false);

const unlinkPatient = async () => {
  try {
    isLoading.value = true;
    const unlinkPatientId = await unlinkPatientAccount({
      id: props.account.id,
    });
    if (unlinkPatientId) {
      toast.success("Patient unlinked successfully", {
        autoClose: 3000,
      });
      fetchPatientRequests();
      closeUnlinkPatientModal();
    }
  } catch (error) {
    console.log(error);
    if (error) {
      toast.error("Error unlinking account ", {
        autoClose: 3000,
      });
    }
  } finally {
    isLoading.value = false;
  }
};
</script>
